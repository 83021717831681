<template>
	<b-collapse
		:open.sync="isCollapseOpen"
		aria-id="simulationData"
		class="st-card has-background-white px-6"
	>
		<template #trigger>
			<div class="columns is-mobile">
				<div class="is-align-self-center column is-8">
					<h1 class="is-title-2-m-d">{{headerTitle}}</h1>
				</div>
				<div v-if="isCollapse" class="column is-4">
					<b-button
						size="is-large"
						icon-pack="st"
						:icon-right="!isCollapseOpen ? 'arrow-down-circle' : 'arrow-up-circle'"
						class="no-focus float-right"
						type="is-ghost"
						aria-controls="simulationData"
					/>
				</div>
			</div>
		</template>
		<!--<h1 class="is-headline-m has-text-blue mb-2 mt-3">{{$t("components.Data.Proposal.details")}}</h1>
		<div class="columns is-mobile is-multiline mb-0">
			<div class="column is-12">
				<div v-for="data in proposal.data" :key="data._id">
					<p v-if="data.includes" class="is-headline-m has-text-black mb-2">- {{data.description}}</p>
				</div>
			</div>
		</div>-->
		<h1 class="is-headline-m has-text-blue mb-2 ">{{$t("components.Data.Proposal.value")[payment_split]}}</h1>
		<div class="columns is-mobile is-multiline">
			<div class="column is-12">
                <p class="is-headline-m has-text-black mb-2">{{proposal.value[payment_split]}}€</p>
            </div>
		</div>
	</b-collapse>
</template>

<script>
	export default {
		name: "ProposalData",
		props: {
			header: {
				type: String,
				default: null,
			},
			proposal: {
				type: Object,
				default: null,
			},
			isCollapse: {
				type: Boolean,
				default: false,
			},
			payment_split: {
				type: String,
				default: null,
			},
		},
		data() {
			return {
				headerTitle: null,
				isCollapseOpen: false,
			};
		},
		created() {
			this.headerTitle = this.header;
			if (!this.headerTitle)
				this.headerTitle = this.$t("components.Data.simulationData");
			if (!this.isCollapse) this.isCollapseOpen = true;
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	
</style>