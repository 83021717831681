<template>
	<div class="components-home-navbar">
		<nav
			v-if="isMobile && !showChat"
			class="navbar is-fixed-top navbar-home has-background-blue "
			role="navigation"
			aria-label="main navigation"
		>
			<div class="navbar-brand">
				<a
					role="button"
					@click.prevent="setSidebarOpen(true)"
					class="navbar-burger has-text-white"
					aria-label="menu"
					aria-expanded="false"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
				<a class="navbar-item" @click="$router.push({name:'UserHome'})">
					<img :src="require('@/assets/icons/logo.svg')" alt="S" />
				</a>
				<a class="navbar-item">
					<img :src="img" alt />
				</a>
			</div>
		</nav>
		<nav
			v-else-if="isMobile && showChat"
			class="navbar is-fixed-top navbar-home has-background-blue chat-header"
			role="navigation"
			aria-label="main navigation"
		>
			<div class="navbar-brand">
				<a
					role="button"
					@click.prevent="setSidebarOpen(true)"
					class="navbar-burger has-text-white"
					aria-label="menu"
					aria-expanded="false"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
				<a class="navbar-item">
					<img :src="getSelectedRoom.picture || getDefaultImg" @error="setDefaultImg" alt />
					<h5 class="is-title-2-m ml-4 has-text-white">{{getSelectedRoom.name}}</h5>
				</a>
			</div>
		</nav>
		<div
			v-else
			class="navbar-desktop columns is-mobile is-multiline has-background-blue"
			:class="!isMobile ? '' : ''"
		>
			<div class="column is-offset-1 is-10 level is-mobile">
				<h2
					class="level-left is-title-1-m-m is-big-title-m-d has-text-white"
				>{{$t("views.Home.hi", [name])}}</h2>
				<img v-if="!isMobile" class="level-right" :src="img" alt />
			</div>
		</div>
	</div>
</template>

<script>
	import chat from "@/mixins/modules/chat";

	export default {
		name: "Navbar",
		mixins: [chat],
		data() {
			return {
				name: "João",
				img: require("@/assets/imgs/avatar.jpg"),
			};
		},
		created() {
			this.name = this.getUser.name;
			if (this.getUser.picture) this.img = this.getUser.picture;
		},
		computed: {
			showChat() {
				if (this.$route.name.includes("Chat")) return true;
				return false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	
	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

	.chat-header {
		img {
			border-radius: 100%;
		}
	}

	.navbar-brand {
		.navbar-item {
			img {
				max-height: 2.75rem;
			}
		}
		.navbar-item:nth-of-type(2) {
			order: 2;
		}
		.navbar-item:nth-of-type(3) {
			order: 10;
			margin-left: auto;
			img {
				border-radius: 9999px;
				border: 2px solid $white;
			}
		}

		.navbar-burger {
			order: 1;
			margin-left: unset;
		}
		.navbar-burger span {
			height: 2px;
			left: calc(50% - 13px);
			width: 26px;
		}
		.navbar-burger span:nth-child(1) {
			top: calc(50% - 6px);
		}
		.navbar-burger span:nth-child(2) {
			top: calc(50% + 1px);
		}
		.navbar-burger span:nth-child(3) {
			top: calc(50% + 8px);
		}
	}

	.columns {
		img {
			border-radius: 9999px;
			border: 2px solid $white;
			max-height: 2.75rem;
		}
	}

	// img {
	// 	max-width: 4rem;
	// 	border: 2px solid $white;
	// 	border-radius: 99999999999999px;
	// }
</style>