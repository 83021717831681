<template>
	<div class="st-card has-background-blue has-text-white mr-6">
		<div class="level is-mobile pl-4 pt-4 pr-4">
			<h2 class="level-left is-headline-m">{{slot1}}</h2>
			<!--<h4 class="level-right is-caption-m">{{slot4}}</h4>-->
			<a class="level-left is-headline-m" :href="'/home/chat/'+slot4"><span class="mdi mdi-chat-processing chat-icon"></span></a>
		</div>
		<div class="level is-mobile pl-4 pr-4">
			<h3 class="level-left is-body-m slot2">{{slot2}}</h3>
			<h4 class="level-right is-caption-m">{{slot3}}</h4>
		</div>

		<span v-if="showBadge" class="badge is-green">{{badgeCount}}</span>
	</div>
</template>

<script>
	export default {
		name: "Card",
		props: {
			slot1: {
				default: "",
				require: false
			},
			slot2: {
				default: "",
				require: false
			},
			slot3: {
				default: "",
				require: false
			},
			slot4: {
				default: "",
				require: false
			},
			showBadge: {
				type: Boolean,
				default: false
			},
			badgeCount: {
				type: Number,
				default: 1
			}
		},
	};
</script>

<style lang="scss" scoped>
	

	.st-card {
		width: 12.5rem !important;
		height: 6rem;
		position: relative;
		border-radius: 15px;
		max-width: 300px;
	}

	.slot2 {
		text-overflow: ellipsis;
		max-width: 70%;
		white-space: nowrap;
		overflow: hidden !important;
		display: inline-block !important;
	}
</style>