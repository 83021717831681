<template>
	<div class="Car-Simulation">
		<div class="columns is-mobile is-multiline is-variable is-0">
			<div class="column is-11 is-offset-0_5">
				<div class="b-steps st-steps">
					<nav class="steps is-black is-animated is-rounded">
						<ul class="step-items">
							<li
								v-show="!isMobile || openStep == 0"
								class="step-item"
								:class="{'is-active': openStep >= 0, 'is-black': openStep==0, 'is-green': openStep > 0}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step1}}</span>
									</div>
									<div class="is-headline-m step-marker">1</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 0 || openStep == 1"
								class="step-item is-black"
								:class="{'is-active': openStep >= 1, 'is-black': openStep==1, 'is-green': openStep > 1}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step2}}</span>
									</div>
									<div class="is-headline-m step-marker">2</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 1 || openStep == 2"
								class="step-item is-black"
								:class="{'is-active': openStep >= 2, 'is-black': openStep==2, 'is-green': openStep > 2}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step3}}</span>
									</div>
									<div class="is-headline-m step-marker">3</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 2 || openStep == 3"
								class="step-item is-black"
								:class="{'is-active': openStep >= 3, 'is-black': openStep==3, 'is-green': openStep > 3}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step4}}</span>
									</div>
									<div class="is-headline-m step-marker">4</div>
								</a>
							</li>
						</ul>
					</nav>
					<section class="step-content mt-4 pb-12">
						<div class="step-item" tabindex="-1" v-show="openStep == 0">
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('persons') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.travellers')">
								</b-field>
								<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="element in t.number_of_persons" :key="element.value" 
										v-model="form.persons"
										:true-value="element.value"
										:native-value="element.value"
										@input="addPerson(element.value);"
									>
									<span>{{element.label}}</span>
								</b-checkbox-button>
								<b-field v-if="form.persons_data.length > 0" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.travellers_age')"></b-field>
								<div v-for="(person,index) in form.persons_data" :key="index" class="column is-2 is-mobile is-multiline is-variable is-2 is-caption-m has-text-white mb-0">
									<b-input
										custom-class="age"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.persons_data[index].age"
									></b-input>
								</div>
							</div>
						</div>

						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 1">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field class="column is-12 is-caption-m has-text-white" :label="$t('fields.travel_types')">
								</b-field>
								<b-checkbox-button 
										class="column is-2-desktop is-12-mobile has-text-white pb-0 pt-0" 
										type="is-primary"
										v-for="(type, i) in t.travel_types" :key="100+i" 
										v-model="form.type"
										:true-value="type"
										:native-value="type"
										@input="form.type = type"
									>
									<span>{{type}}</span>
								</b-checkbox-button>
								<div class="column is-12"></div>
								<b-field :type="invalids.includes('init') ? 'is-error' : ''" v-show="form.type == t.travel_types[0]" class="column is-4 is-caption-m has-text-white" :label="$t('fields.init_date')">
										<b-datepicker
											v-model="form.init"
											custom-class="p-0 h-6 mt-4"
											class="is-headline-m st-datepicker has-text-white"
											:mobile-native="false"
											:min-date="new Date()"
											:max-date="form.end"
											expanded
										></b-datepicker>
									</b-field>
								<b-field :type="invalids.includes('end') ? 'is-error' : ''" v-show="form.type == t.travel_types[0]" class="column is-4 is-caption-m has-text-white" :label="$t('fields.end_date')">
										<b-datepicker
											v-model="form.end"
											custom-class="p-0 h-6 mt-4"
											class="is-headline-m st-datepicker has-text-white"
											:mobile-native="false"
											:min-date="form.init"
											expanded
											:disabled="form.init==null"
										></b-datepicker>
									</b-field>
								<b-field class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.countries')">
									<b-autocomplete
										class="column is-4-desktop is-12-mobile has-text-white p-0"
										v-model="searchCountry"
										:data="filteredCountries"
										placeholder=""
										clearable
										field="name"
										@select="option => pushCountries(option)">
										<template #empty>No results found</template>
									</b-autocomplete>
								</b-field>
								<div class="tags column is-12">
									<span v-for="(country, i) in form.countries" :key="800+i" class="tag is-primary countries">
										{{country}}
										<button class="delete is-small" type="button" @click="deleteCountry(i)"></button>
									</span>
								</div>
					
								<b-field class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.travel_value')">
								</b-field>
								<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(include, i) in t.travel_value" :key="200+i" 
										v-model="form.travel_value"
										:true-value="include"
										:native-value="include"
										@input="form.travel_value = include"
									>
										{{include}}
								</b-checkbox-button>
								<b-field class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.transport')">
								</b-field>
								<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(include, i) in t.transport" :key="300+i" 
										v-model="form.transport"
										:true-value="include"
										:native-value="include"
										@input="form.transport = include"
									>
										{{include}}
								</b-checkbox-button>
								<b-field v-if="form.transport ==  t.transport[0]" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.connecting_flight')"></b-field>
									<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(include, i) in t.connecting_flight" :key="400+i" 
										v-show="form.transport ==  t.transport[0]"
										v-model="form.connecting_flight"
										:true-value="include"
										:native-value="include"
										@input="form.connecting_flight = include"
									>
										{{include}}
								</b-checkbox-button>
								<b-field v-if="form.transport ==  t.transport[0]" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.luggage')"></b-field>
									<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(include, i) in t.luggage" :key="500+i" 
										v-show="form.transport ==  t.transport[0]"
										v-model="form.luggage"
										:true-value="include"
										:native-value="include"
										@input="form.luggage = include"
									>
										{{include}}
								</b-checkbox-button>
								<b-field class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.travel_activities')"></b-field>
									<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(include, i) in t.activities" :key="600+i" 
										v-model="form.activities"
										:true-value="include"
										:native-value="include"
										:disabled="form.activities[form.activities.length-1]==t.activities[3]&&include!=t.activities[3]"
										@input="pushActivities(include)"
									>
										{{include}}
								</b-checkbox-button>
								<b-field v-if="form.activities[0] && form.activities[0] !== t.activities[3]" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.travel_activities_equipment')"></b-field>
								<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(include, i) in t.connecting_flight" :key="700+i" 
										v-show="form.activities[0] && form.activities[0] !== t.activities[3]"
										v-model="form.self_equipment"
										:true-value="include"
										:native-value="include"
										@input="form.self_equipment = include"
									>
										{{include}}
								</b-checkbox-button>
																
							</div>
						</div>

						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 2">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('name') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.name')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.name"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('surname') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.surname')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.surname"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('email') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.email')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="email"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.email"
									></b-input>
								</b-field>
								<b-field
									:type="invalidNif ? 'is-error' : ''"
									:message="invalidNif ? t.errors.invalidNif : ''"
									class="column is-6-desktop is-12-mobile is-caption-m has-text-white"
									:label="$t('fields.nif')"
								>
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										maxlength="9"
										class="is-headline-m st-line-input has-text-white"
										@input="onNifInput"
										v-model="form.nif"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('address') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.address')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.address"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('postal_code') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.postal_code')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.postal_code"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('phone_number') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.phone')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.phone_number"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('age') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white mb-0" :label="$t('fields.age')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.age"
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 3">
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field class="column is-12 is-caption-m has-text-white" :label="$t('fields.payment_type')">
								</b-field>
								<b-checkbox-button
									class="column is-6-desktop is-12-mobile has-text-white" 
									v-for="(option,name, i) in $t('payment_type')" :key="i" 
									v-model="form.payment_type"
									:true-value="name"
									:native-value="name"
									@input="form.payment_type = name"
								>
									{{option}}
								</b-checkbox-button>
							</div>
						</div>
						<div class="step-item" tabindex="-1" v-show="openStep == 4">
							<div class="columns is-mobile is-multiline">
								<div class="column is-12 has-text-centered mb-4">
									<div class="st-check-success has-text-white has-background-success">
										<b-icon icon="check" size="is-large"></b-icon>
									</div>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<h4 class="has-text-white is-title-1-m">{{t.submited}}</h4>
								</div>
								<div class="column is-offset-0_5 is-11 has-text-centered mb-8">
									<h4 class="has-text-white is-headline-m">{{t.wait}}</h4>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<b-button
										expanded
										type="is-black"
										class="has-text-white is-headline-m"
										@click="$router.push({name: 'UserHome'})"
									>{{$t("shared.goHome")}}</b-button>
								</div>
							</div>
						</div>
					</section>
					<nav v-if="openStep<maxStep" class="step-navigation">
						<div class="columns is-mobile">
							<div class="column is-6">
								<b-button
									:disabled="openStep==minStep"
									expanded
									@click="goBack"
									type="is-black"
								>{{$t("shared.previous")}}</b-button>
							</div>
							<div class="column is-6">
								<b-button
									v-if="openStep==maxStep-1"
									expanded
									@click="onSubmit"
									type="is-black"
									:disabled="canGoNext"
								>{{$t("shared.submit")}}</b-button>
								<b-button
									v-else
									expanded
									:disabled="canGoNext"
									@click="goForward"
									type="is-black"
								>{{$t("shared.next")}}</b-button>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>

		<b-loading v-model="isLoading"></b-loading>
	</div>
</template>

<script>
	import { addSimulation } from "@/api/simulations";
	import { validateNIF } from "@/utils/utils";
	import countries from "@/config/countries";

	export default {
		name: "TravelForm",
		data() {
			return {
				simulationType: "travel",
				t: this.$t("components.Forms.Travel"),
				months: this.$t("months"),
				years: [],
				invalids: [],
				isLoading: false,
				minStep: 0,
				maxStep: 4,
				openStep: 0,
				invalidNif: false,
				countries,
				searchCountry: '',
				form: {
					name: null,
					surname: null,
					email: null,
					phone_number: null,
					address: null,
					postal_code: null,
					nif: null,
					type: null,
					init: null,
					end:null,
					countries: [],
					age: null,
					travel_value: null,
					transport: null,
					connecting_flight: null,
					luggage: null,
					activities: [],
					self_equipment: null,
					persons: null,
					persons_data:[],
    				_persons: 0,
					payment_type: null
				},
			};
		},
		created() {
			this.createYears();
			if(this.getUser){
				this.form = {
					name: this.getUser.name,
					surname: this.getUser.surname,
					nif: this.getUser.nif,
					email: this.getUser.email,
					phone_number: this.getUser.phone,
					address: this.getUser.address,
					...(this.getUser.postal_code.number) && {postal_code: this.getUser.postal_code.number + "-" + this.getUser.postal_code.ext},
					type: null,
					countries: [],
					age: null,
					travel_value: null,
					transport: null,
					connecting_flight: null,
					luggage: null,
					activities: [],
					self_equipment: null,
					persons: null,
					persons_data:[],
    				_persons: 0,
					payment_type: null
				}
			}
		},
		methods: {
			pushCountries(country){
				this.form.countries.push(country.name);
				this.searchCountry = '';
			},
			deleteCountry(index){
				this.form.countries.splice(index, 1);
			},
			pushActivities(activity){
				if(activity == this.t.activities[3] && this.form.activities.length > 1 ){
					this.form.activities = [];
					this.form.activities.push(activity);
				}
			},
			addPerson(value){
				this.form.persons = value;

				if(this.form.persons > this.form._persons){
					for(let i = 0; i < (this.form.persons - this.form._persons); i++ ){
						this.form.persons_data.push({
							age:null
						});
					}
					this.form._persons = this.form.persons_data.length;
				} else if(this.form.persons < this.form._persons){
					this.form.persons_data = this.form.persons_data.slice(0, (this.form.persons - this.form._persons));
					this.form._persons = this.form.persons_data.length;
				}
			},
			onNifInput() {
				if (this.form.nif.length == 9)
					this.invalidNif = !validateNIF(this.form.nif);
			},
			onSubmit() {
				this.isLoading = true;
				let copy = Object.assign({}, this.form);
				addSimulation(this.simulationType, copy)
					.then((response) => {
						this.openStep++;
					})
					.catch((error) => {
						if (Array.isArray(error.response.data)) {
							error.response.data.forEach((element) => {
								this.invalids.push(element.param);
							});
          				}
						this.openStep = 0;
						this.$buefy.toast.open({
							message: this.t.api.error,
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goForward() {
				if (this.openStep < this.maxStep) this.openStep++;
			},
			goBack() {
				if (this.openStep > this.minStep) this.openStep--;
			},
			createYears() {
				let currentYear = new Date().getFullYear();
				for (let i = 0; i < 100; i++) {
					this.years.push(currentYear - i);
				}
			},
		},
		computed: {
			filteredCountries() {
				return this.countries.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.searchCountry.toLowerCase()) >= 0
                )
            })
			},
			canGoNext() {
				let can = false;
				let index = this.openStep + 1;
				let checkerAge = arr => arr.every(v => v.age);
				switch (index) {
					case 1:
						
						if (
							this.form.persons == 0
						){
							can = true;
						}else if(this.form.persons && checkerAge(this.form.persons_data)){
							can = true;
						}
						
						break;

					case 2:
						if (
							this.form.type &&
							this.form.countries.length > 0 &&
							this.form.travel_value &&
							this.form.transport &&
							this.form.activities.length > 0
						){	
							can = true;
						}
							
						break;

					case 3:
						if (
							this.form.name &&
							this.form.surname &&
							!this.invalidNif &&
							this.form.email &&
							this.form.age &&
							this.form.phone_number &&
							this.form.address &&
							this.form.postal_code
						)
							can = true;
						break;
					case 4: 
						if (this.form.payment_type) can = true;
						break;

					default:
						break;
				}
				can = !can;
				return can;
			},
		},
	};
</script>

<style lang="scss" scoped>
	

	.column > div:first-of-type {
		position: relative;
	}
	.countries {
		justify-content: flex-start !important;
	}
</style>