<template>
	<div
		class="first-div has-position-relative"
		:class="{'has-navbar-fixed-top': isMobile, 'has-sidebar-fixed': !isMobile , 'views-chat': isChatOpen}"
	>
		<Navbar :key="navbarKey" />
		<Sidebar />

		<router-view @user-update="updatePicture"></router-view>
		<div v-if="showButtonNewInsurance" class="btn-fixed-bottom columns is-mobile">
			<div
				class="column is-offset-1-touch is-10-touch is-offset-4-desktop is-4-desktop is-offset-5-fullhd is-2-fullhd"
			>
				<b-button
					@click="$router.push({name: 'ChooseSimulation'})"
					expanded
					type="is-blue"
				>{{t.new_insurance}}</b-button>
			</div>
			<div :class="{'blur': isMobile}"></div>
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Home/Navbar";
	import Sidebar from "@/components/Home/Sidebar";
	import Card from "@/components/Simulations/Card";
	import Steps from "@/components/Shared/Steps";

	export default {
		name: "Home",
		components: { Navbar, Sidebar, Card, Steps },
		data() {
			return {
				t: this.$t("views.Home"),
				allowedButton: ["MySimulations", "Home", "UserHome", "MyInsurances"],
				navbarKey: 0
			};
		},
		created() {
			if(this.$route.name == "Chat") {
				this.setChatOpen(true);
			}
		},
		methods: {
			updatePicture(){
				this.navbarKey += 1;
			}
		},
		computed: {
			showButtonNewInsurance() {
				if (this.allowedButton.includes(this.$route.name))
					return true;
				return false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	.first-div {
		> .columns:nth-of-type(1) {
			padding-top: $space/2;
			padding-bottom: $space * 1.5;
		}
		@include desktop() {
			background-color: $gray-light;
		}
		/* padding-bottom: 10vh; */
		min-height: 100vh;

		div:nth-of-type(1) {
			img {
				max-width: 4rem;
				border: 2px solid $white;
				border-radius: 99999999999999px;
			}
		}
	}
</style>