<template>
	<div class="first-div has-background-blue is-clipped">
		<CloseTopbar />
		<router-view></router-view>
	</div>
</template>

<script>
	import CloseTopbar from "@/components/Shared/CloseTopbar";
	export default {
		name: "Simulation",
		components: { CloseTopbar },
	};
</script>

<style lang="scss" scoped>
	.first-div {
		min-height: 100vh;
	}
</style>