<template>
  <div class="has-background-gray-light is-full-viewport-height">
    <b-loading :active="isLoading"></b-loading>
    <div class="has-background-blue columns is-mobile is-multiline">
      <div class="column is-12 pb-0">
        <b-button
          type="is-ghost"
          class="has-text-white is-body-m has-icon-margin"
          icon-pack="st"
          icon-left="arrow-left"
          @click="$router.go(-1)"
          >{{ $t("shared.goBack") }}</b-button
        >
      </div>
      <div class="column has-text-white mr-4 ml-4 level is-mobile">
        <h3 class="level-left is-title-1-m">{{ t.simulation }}</h3>
        <h3 class="level-right is-body-m">
          {{
            simulation ? $t(`insuranceTypes.${simulation.simulation_type}`) : ""
          }}
        </h3>
      </div>
    </div>
    <div v-if="!simulation"></div>
    <div v-else>
      <div class="columns is-mobile">
        <div
          class="column is-10-desktop is-offset-1-desktop is-11-touch is-offset-0_5-touch"
        >
          <AutoData
            v-if="simulation.simulation_type == 'car'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <HealthData
            v-if="simulation.simulation_type == 'health'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <LifeData
            v-if="simulation.simulation_type == 'life'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <LifeCreditData
            v-if="simulation.simulation_type == 'life_credit'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <TeethData
            v-if="simulation.simulation_type == 'teeth'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <PersonalAccidentData
            v-if="simulation.simulation_type == 'personal_accident'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <TravelData
            v-if="simulation.simulation_type == 'travel'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <BicycleData
            v-if="simulation.simulation_type == 'bicycle'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <MotorcycleData
            v-if="simulation.simulation_type == 'motorcycle'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <IndependentWorkData
            v-if="simulation.simulation_type == 'independent_work'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <AnimalData
            v-if="simulation.simulation_type == 'animal'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <WeaponsData
            v-if="simulation.simulation_type == 'weapons'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <HouseData
            v-if="simulation.simulation_type == 'house'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <OtherData
            v-if="simulation.simulation_type == 'other'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <CompanyAutoData
            v-if="simulation.simulation_type == 'company_car'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <MultiRiskData
            v-if="simulation.simulation_type == 'multi_risk'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <CompanyHealthData
            v-if="simulation.simulation_type == 'company_health'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <CompanyLifeData
            v-if="simulation.simulation_type == 'company_life'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <ResponsabilityData
            v-if="simulation.simulation_type == 'responsability'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
          <WorkAccidentData
            v-if="simulation.simulation_type == 'work_accident'"
            class="mt-8"
            :simulation="simulation"
            :isCollapse="true"
          />
        </div>
      </div>

      <div class="columns is-mobile mt-4">
        <div
          class="column is-10-desktop is-offset-1-desktop is-11-touch is-offset-0_5-touch"
        >
          <div class="level is-mobile">
            <div
              v-if="simulation.simulation_type != 'other'"
              class="level-left"
            >
              <h4 class="is-headline-m">{{ t.proposals }}</h4>
              <span class="badge not-fixed is-green ml-4">{{
                simulation.proposals.length
              }}</span>
            </div>
            <div v-else-if="simulation.data.room" class="level-left pl-4">
              <h4 class="is-headline-d">{{ t.talk_mediator }}</h4>
              <a
                class="level-left is-headline-d ml-1"
                :href="'/home/chat/' + simulation.data.room"
                ><span class="mdi mdi-chat-processing chat-icon"></span
              ></a>
            </div>
            <div
              v-show="simulation.simulation_type != 'other'"
              class="level-right"
            >
              <a class="is-caption-m" @click="see_all = !see_all">{{
                $t("shared.see_all")
              }}</a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!see_all && simulation.simulation_type != 'other'"
        class="columns is-mobile is-multiline"
      >
        <div class="column is-offset-1 is-10 card-row">
          <Card
            v-for="(prop, i) in simulation.proposals"
            :key="i"
            @click.native="openProposal(prop.mediator._id)"
            :slot1="prop.mediator.business_name"
            :slot2="$t('shared.startingAt', [getSmallerValue(prop)])"
          />
        </div>
      </div>
      <div
        v-show="see_all && simulation.simulation_type != 'other'"
        class="columns is-mobile is-multiline"
      >
        <div class="column is-offset-1 is-10 px-6 st-card has-background-white">
          <b-field grouped group-multiline>
            <b-select v-model="selectedLocation">
              <option value="">{{ t.table.filter_location }}</option>
              <option
                v-for="(location, index) in locations"
                :key="index"
                :value="location"
                >{{ location }}</option
              >
            </b-select>
          </b-field>
          <b-table
            class="props-table has-text-black has-background-white mt-4"
            :hoverable="true"
            :mobile-cards="true"
            :data="getData"
            :sticky-header="true"
            defaultSortDirection="asc"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="value"
            v-if="load"
          >
            <b-table-column
              field="plan"
              :label="t.table.plan"
              centered
              v-slot="props"
            >
              {{ props.row.plan }}
            </b-table-column>
            <b-table-column
              field="mediator"
              :label="t.table.mediator"
              centered
              v-slot="props"
            >
              {{ props.row.mediator }}
            </b-table-column>
            <b-table-column
              field="location"
              :label="t.table.location"
              centered
              v-slot="props"
            >
              {{ props.row.location }}
            </b-table-column>
            <b-table-column
              field="value"
              sortable
              width="150px"
              :label="t.table.value[payment_split]"
              centered
              v-slot="props"
            >
              {{ props.row.value }}
            </b-table-column>
            <b-table-column field="link" v-slot="props" centered label="">
              <b-button
                class="clear-button has-text-black"
                @click="openProposal(props.row.link)"
              >
                <b-icon pack="st" icon="arrow-right" size="is-small"></b-icon>
              </b-button>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSimulationByIdAndProposals,
  updateSimulationNewProposals,
} from "@/api/simulations";
import { getLocation } from "@/api/location";
import AutoData from "@/components/Data/Auto";
import HealthData from "@/components/Data/Health";
import LifeData from "@/components/Data/Life";
import LifeCreditData from "@/components/Data/LifeCredit";
import TeethData from "@/components/Data/Teeth";
import PersonalAccidentData from "@/components/Data/PersonalAccident";
import TravelData from "@/components/Data/Travel";
import BicycleData from "@/components/Data/Bicycle";
import MotorcycleData from "@/components/Data/Motorcycle";
import IndependentWorkData from "@/components/Data/IndependentWork";
import AnimalData from "@/components/Data/Animal";
import WeaponsData from "@/components/Data/Weapons";
import HouseData from "@/components/Data/House";
import OtherData from "@/components/Data/Other";

import CompanyAutoData from "@/components/Data/CompanyAuto";
import MultiRiskData from "@/components/Data/MultiRisk";
import CompanyHealthData from "@/components/Data/CompanyHealth";
import CompanyLifeData from "@/components/Data/CompanyLife";
import ResponsabilityData from "@/components/Data/Responsability";
import WorkAccidentData from "@/components/Data/WorkAccident";
import Card from "./Card.vue";

export default {
  name: "SimulationID",
  components: {
    Card,
    AutoData,
    HealthData,
    LifeData,
    LifeCreditData,
    TeethData,
    PersonalAccidentData,
    TravelData,
    BicycleData,
    MotorcycleData,
    IndependentWorkData,
    AnimalData,
    WeaponsData,
    HouseData,
    OtherData,
    CompanyAutoData,
    MultiRiskData,
    CompanyHealthData,
    CompanyLifeData,
    ResponsabilityData,
    WorkAccidentData,
  },
  data() {
    return {
      isCollapseOpen: false,
      t: this.$t("components.Simulations.SimulationID"),
      simulation: null,
      isLoading: false,
      proposals: [],
      see_all: false,
      payment_split: "annual",
      locations: [],
      load: false,
      order_locations: [],
      selectedLocation: "",
    };
  },
  created() {
    this.getSimulationById();
  },
  methods: {
    getSimulationById() {
      this.isLoading = true;
      getSimulationByIdAndProposals(this.$route.params.id)
        .then((response) => {
          this.simulation = response.data.data;

          for (let i = 0; i < this.simulation.proposals.length; i++) {
            if (this.simulation.proposals[i].mediator.postal_code.number != null && this.simulation.proposals[i].mediator.postal_code.ext != null) {
              getLocation({
                zip_code:
                  this.simulation.proposals[i].mediator.postal_code.number +
                  "-" +
                  this.simulation.proposals[i].mediator.postal_code.ext,
              })
                .then((response) => {
                  this.simulation.proposals[i].location =
                    response.data.location;
                  this.locations.push(response.data.location.city);
                })
                .catch((error) => {
                });
            }else{
				this.locations.push('');
			}
          }
          this.load = true;
        })
        .catch((error) => {})
        .finally(() => {
          this.isLoading = false;
          if (this.simulation.new_proposals != 0) this.updateSimulation();
        });
    },
    updateSimulation() {
      updateSimulationNewProposals(this.$route.params.id)
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    },
    getSmallerValue(proposal) {
      let smaller = proposal.proposals[0].value.annual;
      for (let proposal of proposal.proposals) {
        if (proposal.value.annual < smaller) smaller = proposal.value.annual;
      }

      return Math.round((smaller/12) * 100) / 100 ;
    },
    openProposal(_id) {
      this.$router.push({
        name: "ProposalID",
        params: {
          simulationId: this.simulation._id,
          mediatorId: _id,
        },
      });
    },
  },
  computed: {
    getData() {
      let data = [];
      let proposals = this.simulation.proposals.filter(
        (proposal) => proposal.location.city == this.selectedLocation
      );
      
      if (proposals.length <= 0) proposals = this.simulation.proposals;
      proposals.forEach((proposal, index) => {
        proposal.proposals.forEach((prop)=>{
          let values = {
            plan: prop.plan,
            mediator: proposal.mediator.business_name,
            value:  prop.value[this.payment_split],
            link: proposal.mediator._id,
            location: this.locations[index],
          };
          data.push(values);
        })
        
      });
      //console.log(data);
      return data;
    },
    getColumns() {
      let columns = [
        {
          field: "plan",
          label: this.t.table.plan,
          headerClass: "is-subhead-m",
          centered: true,
        },
        {
          field: "mediator",
          label: this.t.table.mediator,
          headerClass: "is-subhead-m",
          centered: true,
        },
        {
          field: "location",
          label: this.t.table.location,
          headerClass: "is-subhead-m",
          centered: true,
        },
        {
          field: "value",
          label: this.t.table.value[this.payment_split],
          headerClass: "is-subhead-m",
          centered: true,
          width: "150px",
          sortable: true,
        },
        {
          field: "link",
          headerClass: "is-subhead-m",
          centered: true,
          width: "50px",
        },
      ];
      return columns;
    },
  },
};
</script>

<style scoped>
.chat-icon,
.chat-icon:hover {
  color: #1981fc !important;
}
</style>
