<template>
	<div class="Car-Simulation">
		<div class="columns is-mobile is-multiline is-variable is-0">
			<div class="column is-11 is-offset-0_5">
				<div class="b-steps st-steps">
					<nav class="steps is-black is-animated is-rounded">
						<ul class="step-items">
							<li
								class="step-item"
								:class="{'is-active': openStep >= 0, 'is-black': openStep==0, 'is-green': openStep > 0}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step1}}</span>
									</div>
									<div class="is-headline-m step-marker">1</div>
								</a>
							</li>
							<li
								class="step-item is-black"
								:class="{'is-active': openStep >= 1, 'is-black': openStep==1, 'is-green': openStep > 1}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step2}}</span>
									</div>
									<div class="is-headline-m step-marker">2</div>
								</a>
							</li>
						</ul>
					</nav>
					<section class="step-content mt-4 pb-12">
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 0">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('name') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.name')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.name"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('surname') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.surname')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.surname"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('email') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white" :label="$t('fields.email')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="email"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.email"
									></b-input>
								</b-field>
								<b-field
									:type="invalidNif ? 'is-error' : ''"
									:message="invalidNif ? t.errors.invalidNif : ''"
									class="column is-6-desktop is-12-mobile is-caption-m has-text-white"
									:label="$t('fields.nif')"
								>
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										maxlength="9"
										class="is-headline-m st-line-input has-text-white"
										@input="onNifInput"
										v-model="form.nif"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('phone_number') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.phone')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.phone_number"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('address') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.address')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.address"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('postal_code') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.postal_code')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.postal_code"
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 1">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field 
									:type="invalids.includes('informations') ? 'is-error' : ''"
									class="column is-12 is-caption-m has-text-white" style="width:80vw;" :label="$t('fields.description')">
									<b-input
										custom-class="h-6"
										outlined
										type="textarea"
										class="is-headline-m st-line-input has-text-white"
										expanded
										v-model="form.informations"
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="step-item" tabindex="-1" v-show="openStep == 2">
							<div class="columns is-mobile is-multiline">
								<div class="column is-12 has-text-centered mb-4">
									<div class="st-check-success has-text-white has-background-success">
										<b-icon icon="check" size="is-large"></b-icon>
									</div>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<h4 class="has-text-white is-title-1-m">{{t.submited}}</h4>
								</div>
								<div class="column is-offset-0_5 is-11 has-text-centered mb-8">
									<h4 class="has-text-white is-headline-m">{{t.wait}}</h4>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<b-button
										expanded
										type="is-black"
										class="has-text-white is-headline-m"
										@click="$router.push({name: 'UserHome'})"
									>{{$t("shared.goHome")}}</b-button>
								</div>
							</div>
						</div>
					</section>
					<nav v-if="openStep<maxStep" class="step-navigation">
						<div class="columns is-mobile">
							<div class="column is-6">
								<b-button
									:disabled="openStep==minStep"
									expanded
									@click="goBack"
									type="is-black"
								>{{$t("shared.previous")}}</b-button>
							</div>
							<div class="column is-6">
								<b-button
									v-if="openStep==maxStep-1"
									expanded
									@click="onSubmit"
									type="is-black"
									:disabled="canGoNext"
								>{{$t("shared.submit")}}</b-button>
								<b-button
									v-else
									expanded
									:disabled="canGoNext"
									@click="goForward"
									type="is-black"
								>{{$t("shared.next")}}</b-button>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>

		<b-loading v-model="isLoading"></b-loading>
	</div>
</template>

<script>
	import { addSimulation } from "@/api/simulations";
	import { validateNIF } from "@/utils/utils";

	export default {
		name: "WeaponsForm",
		data() {
			return {
				simulationType: "other",
				t: this.$t("components.Forms.Other"),
				isLoading: false,
				invalids: [],
				minStep: 0,
				maxStep: 2,
				openStep: 0,
				invalidNif: false,
				form: {
					name: null,
					surname: null,
					email: null,
					phone_number: null,
					nif: null,
					address: null,
					postal_code: null,
					rc_hunter: null,
					rc_use_weapon: null,
					weapons_number: null,
					_weapons_number: null,
					weapons:[],
					informations: null,
					payment_type: null
				},
			};
		},
		created() {
			if(this.getUser){
				this.form.name = this.getUser.name;
				this.form.surname = this.getUser.surname;
				this.form.nif = this.getUser.nif;
				this.form.email = this.getUser.email;
				this.form.phone_number = this.getUser.phone;
				this.form.address = this.getUser.address;
				if(this.getUser.postal_code.number) this.form.postal_code = this.getUser.postal_code.number + "-" + this.getUser.postal_code.ext;
			}
		},
		methods: {
			addWeapon(){
				if(this.form.weapons_number > this.form._weapons_number){
					this.form.weapons.push({
						brand: null,
						model:null,
						serial_number:null,
						class: null
					});
					this.form._weapons_number = this.form._weapons_number + 1;
				} else if(this.form.weapons_number < this.form._weapons_number){
					this.form.weapons.pop();
					this.form._weapons_number = this.form._weapons_number - 1;
				}
			},
			onNifInput() {
				if (this.form.nif.length == 9)
					this.invalidNif = !validateNIF(this.form.nif);
			},
			onSubmit() {
				this.isLoading = true;
				let copy = Object.assign({}, this.form);
				addSimulation(this.simulationType, copy)
					.then((response) => {
						this.openStep++;
					})
					.catch((error) => {
						if (Array.isArray(error.response.data)) {
							error.response.data.forEach((element) => {
								this.invalids.push(element.param);
							});
          				}
						this.openStep = 0;
						this.$buefy.toast.open({
							message: this.t.api.error,
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goForward() {
				if (this.openStep < this.maxStep) this.openStep++;
			},
			goBack() {
				if (this.openStep > this.minStep) this.openStep--;
			},
		},
		computed: {
			canGoNext() {
				let can = false;
				let index = this.openStep + 1;

				switch (index) {
					case 1:
						if (
							this.form.name &&
							this.form.surname &&
							!this.invalidNif &&
							this.form.email &&
							this.form.phone_number 
						)
							can = true;
						break;

					case 2:
						if (
							this.form.rc_hunter &&
							this.form.rc_use_weapon &&
							this.form.weapons_number == this.form.weapons.length &&
							this.form.payment_type
						){
							let checkerBrand = arr => arr.every(v => v.brand);
							let checkerModel = arr => arr.every(v => v.model);
							let checkerSerialNumber = arr => arr.every(v => v.serial_number);
							let checkerClass = arr => arr.every(v => v.class);

							if(	checkerBrand(this.form.persons_data) &&
								checkerModel(this.form.persons_data) &&
								checkerSerialNumber(this.form.persons_data) &&
								checkerClass(this.form.persons_data) )
							 can = true;
						}
						can = true;
							
						break;

					default:
						break;
				}
				can = !can;
				return can;
			},
		},
	};
</script>

<style lang="scss" scoped>
	

	.column > div:first-of-type {
		position: relative;
	}
</style>