export default {
  individual: [
    {
      name: "car",
      img: require("@/assets/imgs/LandingPage/car.png"),
      route: "CarSimulation",
    },
    {
      name: "health",
      img: require("@/assets/imgs/LandingPage/health.png"),
      route: "HealthSimulation",
    },
    {
      name: "house",
      img: require("@/assets/imgs/LandingPage/house.png"),
      route: "HouseSimulation",
    },
    {
      name: "life",
      img: require("@/assets/imgs/LandingPage/life.png"),
      route: "LifeSimulation",
    },
    {
      name: "life_credit",
      img: require("@/assets/imgs/LandingPage/life.jpg"),
      route: "LifeCreditSimulation",
    },
    {
      name: "teeth",
      img: require("@/assets/imgs/LandingPage/teeth.png"),
      route: "TeethSimulation",
    },
    {
      name: "personal_accident",
      img: require("@/assets/imgs/LandingPage/personal_accident.jpg"),
      route: "PersonalAccidentSimulation",
    },
    {
      name: "independent_work",
      img: require("@/assets/imgs/LandingPage/work_accidents.jpg"),
      route: "IndependentWorkSimulation",
    },
    {
      name: "travel",
      img: require("@/assets/imgs/LandingPage/travel.jpg"),
      route: "TravelSimulation",
    },
    {
      name: "bicycle",
      img: require("@/assets/imgs/LandingPage/bicycle.jpg"),
      route: "BicycleSimulation",
    },
    {
      name: "motorcycle",
      img: require("@/assets/imgs/LandingPage/motorcycle.jpg"),
      route: "MotorcycleSimulation",
    },
    {
      name: "animal",
      img: require("@/assets/imgs/LandingPage/animals.jpg"),
      route: "AnimalSimulation",
    },
    {
      name: "weapons",
      img: require("@/assets/imgs/LandingPage/weapons.jpg"),
      route: "WeaponsSimulation",
    },
    {
      name: "other",
      img: require("@/assets/imgs/LandingPage/travel.jpg"),
      route: "OtherSimulation",
    },
  ],
  company: [
    {
      name: "car",
      img: require("@/assets/imgs/LandingPage/car.png"),
      route: "CompanyCarSimulation",
    },
    {
      name: "multi_risk",
      img: require("@/assets/imgs/LandingPage/house.png"),
      route: "MultiRiskSimulation",
    },
    {
      name: "health",
      img: require("@/assets/imgs/LandingPage/health.png"),
      route: "CompanyHealthSimulation",
    },
    {
      name: "life",
      img: require("@/assets/imgs/LandingPage/life.png"),
      route: "CompanyLifeSimulation",
    },
    {
      name: "responsability",
      img: require("@/assets/imgs/LandingPage/personal_accident.jpg"),
      route: "ResponsabilitySimulation",
    },
    {
      name: "work_accident",
      img: require("@/assets/imgs/LandingPage/work_accidents.jpg"),
      route: "WorkAccidentSimulation",
    },
  ],
};
