<template>
	<div class="Car-Simulation">
		<div class="columns is-mobile is-multiline is-variable is-0">
			<div class="column is-11 is-offset-0_5">
				<div class="b-steps st-steps">
					<nav class="steps is-black is-animated is-rounded">
						<ul class="step-items">
							<li
								v-show="!isMobile || openStep == 0"
								class="step-item"
								:class="{'is-active': openStep >= 0, 'is-black': openStep==0, 'is-green': openStep > 0}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step1}}</span>
									</div>
									<div class="is-headline-m step-marker">1</div>
								</a>
							</li>
							<li
								v-show="!isMobile  || openStep == 0 || openStep == 1"
								class="step-item is-black"
								:class="{'is-active': openStep >= 1, 'is-black': openStep==1, 'is-green': openStep > 1}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step2}}</span>
									</div>
									<div class="is-headline-m step-marker">2</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 1 || openStep == 2"
								class="step-item is-black"
								:class="{'is-active': openStep >= 2, 'is-black': openStep==2, 'is-green': openStep > 2}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step3}}</span>
									</div>
									<div class="is-headline-m step-marker">3</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 2 || openStep == 3"
								class="step-item is-black"
								:class="{'is-active': openStep >= 3, 'is-black': openStep==3, 'is-green': openStep > 3}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step4}}</span>
									</div>
									<div class="is-headline-m step-marker">4</div>
								</a>
							</li>
						</ul>
					</nav>
					<section class="step-content mt-4 pb-12">
						<div class="step-item is-align-self-center" tabindex="0" v-show="openStep == 0">
							<h4
								v-if="invalidLicense || !licenseComplete && !haveLicense"
								class="is-title-1-m has-text-white mb-7"
							>{{t.insertLicense}}</h4>
							<div v-if="!haveLicense" id="div-license-plate">
								<input
									@keyup="onLicenseInput('a', $event)"
									v-model="license.a"
									maxlength="2"
									class="license"
									type="text"
								/>
								<input
									@keyup="onLicenseInput('b', $event)"
									@keyup.delete="onLicenseDelete('b', $event)"
									v-model="license.b"
									maxlength="2"
									class="license"
									type="text"
								/>
								<input
									@keyup="onLicenseInput('c', $event)"
									@keyup.delete="onLicenseDelete('c', $event)"
									v-model="license.c"
									maxlength="2"
									class="license"
									type="text"
								/>
								<span class="is-headline-m month-number">{{form.car.month ? form.car.month.number : ''}}</span>
								<span
									class="is-headline-m year-number"
								>{{form.car.year ? form.car.year.toString().substring(2,4) : ''}}</span>
								<img
									id="license-plate"
									@load="onImageLoaded"
									:src="require('@/assets/imgs/licenseplate.svg')"
									alt
								/>
							</div>
							<h4
								v-if="invalidLicense"
								class="has-text-centered has-error is-caption-d"
							>{{t.errors.invalidLicense}}</h4>
							<b-button
								v-if="invalidLicense || !licenseComplete && !haveLicense"
								class="is-headline-m has-text-white mt-8"
								expanded
								type="is-ghost"
								@click="haveLicense=true"
							>{{t.donthaveLicense}}</b-button>
							<div v-if="licenseComplete || haveLicense" class="full-width mt-8">
								<h4 class="has-text-white is-title-1-m mb-8">{{t.isThis}}</h4>
								<div class="columns is-mobile is-multiline is-variable is-2">
									<b-field :type="invalids.includes('car.brand') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.brand')">
										<b-input
											custom-class="p-0 h-6"
											outlined
											type="text"
											class="is-headline-m st-line-input has-text-white"
											v-model="form.car.brand"
										></b-input>
									</b-field>
									<b-field :type="invalids.includes('car.model') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.model')">
										<b-input
											custom-class="p-0 h-6"
											outlined
											type="text"
											class="is-headline-m st-line-input has-text-white"
											v-model="form.car.model"
										></b-input>
									</b-field>
									<b-field :type="invalids.includes('car.pack') ? 'is-error' : ''" class="column is-3-desktop is-6-mobile is-caption-m has-text-white" :label="$t('fields.pack')">
										<b-input
											custom-class="p-0 h-6"
											outlined
											type="text"
											class="is-headline-m st-line-input has-text-white"
											v-model="form.car.pack"
										></b-input>
									</b-field>
									<b-field :type="invalids.includes('car.cylinder') ? 'is-error' : ''" class="column is-3-desktop is-6-mobile is-caption-m has-text-white" :label="$t('fields.cylinder')">
										<b-input
											custom-class="p-0 h-6"
											outlined
											type="number"
											class="is-headline-m st-line-input has-text-white"
											v-model="form.car.cylinder"
										></b-input>
									</b-field>
									<b-field :type="invalids.includes('car.month') ? 'is-error' : ''" class="column is-3-desktop is-6-mobile is-caption-m has-text-white" :label="$t('fields.month')">
										<b-select
											class="p-0 h-6 is-headline-m st-line-input has-text-white"
											outlined
											expanded
											inherit
											v-model="form.car.month"
										>
											<option v-for="(month, i) in months" :key="i" :value="month">{{month.name}}</option>
										</b-select>
									</b-field>
									<b-field :type="invalids.includes('car.year') ? 'is-error' : ''" class="column is-3-desktop is-6-mobile is-caption-m has-text-white" :label="$t('fields.year')">
										<b-select
											class="p-0 h-6 is-headline-m st-line-input has-text-white"
											outlined
											expanded
											inherit
											v-model="form.car.year"
										>
											<option v-for="(year) in years" :key="year" :value="year">{{year}}</option>
										</b-select>
									</b-field>
									<b-field :type="invalids.includes('car.isComercial') ? 'is-error' : ''" class="column is-12 has-text-white is-caption-m">
										<b-checkbox
											class="st-checkbox"
											v-model="form.car.isComercial"
											type="is-blue"
										>{{t.comercial}}</b-checkbox>
									</b-field>
								</div>
							</div>
						</div>
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 1">
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('person.name') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.name')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.person.name"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('person.surname') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.surname')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.person.surname"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('person.birthdate') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.birthdate')">
									<b-datepicker
										v-model="form.person.birthdate"
										custom-class="p-0 h-6"
										class="is-headline-m st-datepicker has-text-white"
										:mobile-native="false"
										:max-date="new Date()"
										expanded
									></b-datepicker>
								</b-field>
								<b-field :type="invalids.includes('person.gender') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.gender')">
									<b-select
										class="p-0 h-6 is-headline-m st-line-input has-text-white"
										outlined
										expanded
										v-model="form.person.gender"
									>
										<option
											v-for="(gender, i) in $t('genders')"
											:key="i"
											:value="gender.value"
										>{{gender.text}}</option>
									</b-select>
								</b-field>
								<b-field
									:type="invalids.includes('person.address') ? 'is-error' : ''"
									class="column is-12 is-caption-m has-text-white"
									:label="$t('fields.address')"
								>
									<b-input
										custom-class="p-0 h-6"
										class="is-headline-m st-line-input has-text-white"
										outlined
										autocomplete="on"
										name="address"
										type="text"
										v-model="form.person.address"
									></b-input>
								</b-field>
								<b-field
									:type="invalidNif ? 'is-error' : ''"
									:message="invalidNif ? t.errors.invalidNif : ''"
									class="column is-6-desktop is-12-mobile is-caption-m has-text-white"
									:label="$t('fields.nif')"
								>
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										maxlength="9"
										class="is-headline-m st-line-input has-text-white"
										@input="onNifInput"
										v-model="form.person.nif"
									></b-input>
								</b-field>
								<b-field
									:type="invalids.includes('person.drive_license_date') ? 'is-error' : ''"
									class="column is-6-desktop is-12-mobile is-caption-m has-text-white"
									:label="$t('fields.drive_license_date')"
								>
									<b-datepicker
										v-model="form.person.drive_license_date"
										custom-class="p-0 h-6"
										class="is-headline-m st-datepicker has-text-white"
										position="is-top-right"
										:mobile-native="false"
										:max-date="new Date()"
										expanded
									></b-datepicker>
								</b-field>
							</div>
						</div>
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 2">
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('coverages') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.coverages')">
								</b-field>
								<b-checkbox 
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(option, i) in t.coverages" :key="100+i" 
										v-model="form.coverages"
										:true-value="option"
										:native-value="option"
									>
											{{option}}
								</b-checkbox>
							</div>
						</div>
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 3">
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('person.payment_type') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white" :label="$t('fields.payment_type')">
									<b-checkbox 
										class="column is-6 has-text-white" 
										v-for="(option,name, i) in $t('payment_type')" :key="i" 
										v-model="form.payment_type"
										:true-value="name"
										:native-value="name"
									>
										{{option}}
									</b-checkbox>
								</b-field>
							</div>
						</div>
						<div class="step-item" tabindex="-1" v-show="openStep == 4">
							<div class="columns is-mobile is-multiline">
								<div class="column is-12 has-text-centered mb-4">
									<div class="st-check-success has-text-white has-background-success">
										<b-icon icon="check" size="is-large"></b-icon>
									</div>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<h4 class="has-text-white is-title-1-m">{{t.submited}}</h4>
								</div>
								<div class="column is-offset-0_5 is-11 has-text-centered mb-8">
									<h4 class="has-text-white is-headline-m">{{t.wait}}</h4>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<b-button
										expanded
										type="is-black"
										class="has-text-white is-headline-m"
										@click="$router.push({name: 'UserHome'})"
									>{{$t("shared.goHome")}}</b-button>
								</div>
							</div>
						</div>
					</section>
					<nav v-if="openStep<maxStep" class="step-navigation">
						<div class="columns is-mobile">
							<div class="column is-6">
								<b-button
									:disabled="openStep==minStep"
									expanded
									@click="goBack"
									type="is-black"
								>{{$t("shared.previous")}}</b-button>
							</div>
							<div class="column is-6">
								<b-button
									v-if="openStep==maxStep-1"
									expanded
									@click="onSubmit"
									type="is-black"
									:disabled="canGoNext"
								>{{$t("shared.submit")}}</b-button>
								<b-button
									v-else
									expanded
									:disabled="canGoNext"
									@click="goForward"
									type="is-black"
								>{{$t("shared.next")}}</b-button>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>

		<b-loading v-model="isLoading"></b-loading>
	</div>
</template>

<script>
	import { getLicensePlate, addSimulation } from "@/api/simulations";
	import { validateNIF, validateLicensePlate, transformLicensePlate } from "@/utils/utils";

	export default {
		name: "CarForm",
		data() {
			return {
				simulationType: "car",
				t: this.$t("components.Forms.Car"),
				months: this.$t("months"),
				invalids:[],
				years: [],
				isLoading: false,
				minStep: 0,
				maxStep: 4,
				openStep: 0,
				invalidLicense: false,
				invalidNif: false,
				licenseComplete: false,
				haveLicense: false,
				license: {
					a: null,
					b: null,
					c: null,
				},
				form: {
					car: {
						brand: null,
						model: null,
						pack: null,
						month: null,
						year: null,
						isComercial: false,
						cylinder: null
					},
					person: {
						name: null,
						surname: null,
						birthdate: null,
						gender: null,
						nif: null,
						drive_license_date: null,
						address: null
					},
					payment_type: null,
					coverages:[]
				},
			};
		},
		created() {
			this.createYears();
			this.form.person = {
				name: this.getUser.name,
				surname: this.getUser.surname,
				...(this.getUser.birthdate) && {birthdate: new Date(this.getUser.birthdate)},
				...(this.getUser.drive_license_date) && {drive_license_date: new Date(this.getUser.drive_license_date)},
				gender: this.getUser.gender,
				nif: this.getUser.nif,
				address: this.getUser.address
			};
		},
		methods: {
			onLicenseInput(where, event) {
				switch (where) {
					case "a":
						if (this.license.a.length >= 2)
							event.target.nextElementSibling.focus();
						break;
					case "b":
						if (this.license.b.length >= 2)
							event.target.nextElementSibling.focus();
						break;
					case "c":
						if (this.license.c.length >= 2 && !this.licenseComplete) {
							this.licenseComplete = true;
							this.onLicenseComplete();
						}
						break;

					default:
						break;
				}
			},
			onLicenseDelete(where, event) {
				switch (where) {
					case "b":
						if (this.license.b.length == 0)
							event.target.previousElementSibling.focus();
						break;
					case "c":
						this.licenseComplete = false;
						if (this.license.c.length == 0)
							event.target.previousElementSibling.focus();
						break;

					default:
						break;
				}
			},
			onLicenseComplete() {
				let license = `${this.license.a}${this.license.b}${this.license.c}`;
				this.invalidLicense = !validateLicensePlate(license);

				if (!this.invalidLicense) {
					this.isLoading = true;
					getLicensePlate(
						`${this.license.a}${this.license.b}${this.license.c}`
					)
						.then((response) => {
							this.form.car.brand =
								response.data.data.CheckPortugalResult.vehicleData.CarMake.CurrentTextValue;
							this.form.car.model =
								response.data.data.CheckPortugalResult.vehicleData.CarModel;
							// this.form.car.brand = response.data.data.CheckPortugalResult.vehicleData.CarMake.CurrentTextValue;
							// this.form.car.brand = response.data.data.CheckPortugalResult.vehicleData.CarMake.CurrentTextValue;
							this.form.car.year =
								response.data.data.CheckPortugalResult.vehicleData.RegistrationYear;
						})
						.catch((error) => {})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},
			onImageLoaded() {
				let width = document.getElementById("license-plate").offsetWidth;
				document.getElementById("div-license-plate").style.width =
					width + "px";
			},
			onNifInput() {
				if (this.form.person.nif.length == 9)
					this.invalidNif = !validateNIF(this.form.person.nif);
			},
			onSubmit() {
				this.isLoading = true;
				let copy = Object.assign({}, this.form);
				copy.car.month = copy.car.month.number;
				if(!this.haveLicense) copy.car.plate = transformLicensePlate(`${this.license.a}${this.license.b}${this.license.c}`);
				addSimulation(this.simulationType, copy)
					.then((response) => {
						this.openStep++;
					})
					.catch((error) => {
						if (Array.isArray(error.response.data)) {
							error.response.data.forEach((element) => {
								this.invalids.push(element.param);
							});
          				}
						this.openStep = 0;
						this.$buefy.toast.open({
							message: this.t.api.error,
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goForward() {
				if (this.openStep < this.maxStep) this.openStep++;
			},
			goBack() {
				if (this.openStep > this.minStep) this.openStep--;
			},
			createYears() {
				let currentYear = new Date().getFullYear();
				for (let i = 0; i < 100; i++) {
					this.years.push(currentYear - i);
				}
			},
		},
		computed: {
			canGoNext() {
				let can = false;
				let index = this.openStep + 1;

				switch (index) {
					case 1:
						if(!this.haveLicense){
							if (
								this.form.car.brand &&
								this.form.car.model &&
								this.form.car.month &&
								this.form.car.year &&
								this.form.car.cylinder &&
								!this.invalidLicense 
							)
								can = true;
						}else{
							if (
								this.form.car.brand &&
								this.form.car.model &&
								this.form.car.month &&
								this.form.car.year &&
								this.form.car.cylinder
							)
								can = true;
						}
						
						break;

					case 2:
						if (
							this.form.person.name &&
							this.form.person.surname &&
							this.form.person.birthdate &&
							this.form.person.gender &&
							this.form.person.drive_license_date &&
							this.form.person.address &&
							this.form.person.nif &&
							!this.invalidNif &&
							this.form.person.nif.length == 9
						)
							can = true;
						break;
					case 3:
						if (this.form.coverages.length > 0)
							can = true;
						break;
					
					case 4:
						if (
							this.form.payment_type
						)
							can = true;
						break;

					default:
						break;
				}
				can = !can;
				return can;
			},
		},
	};
</script>

<style lang="scss" scoped>
	

	.column > div:first-of-type {
		position: relative;
	}

	#div-license-plate {
		position: relative;
		width: 100%;
		max-width: 100%;
		margin: auto;

		.month-number {
			position: absolute;
			bottom: 25%;
			right: 5.3%;
		}
		.year-number {
			position: absolute;
			right: 5.3%;
			top: 17%;
		}
	}
	.license {
		position: absolute;
		top: 20%;
		width: 17%;
		height: 56%;
		font-size: 1.5rem;
		text-transform: uppercase;
		background-color: unset;
		border: unset;
		text-align: center;
	}
	.license:nth-of-type(1) {
		left: 21%;
	}
	.license:nth-of-type(2) {
		left: 42%;
	}
	.license:nth-of-type(3) {
		left: 61%;
	}

	.license:focus-visible {
		outline: unset;
	}
</style>