<template>
	<div class="has-background-blue is-full-viewport-height has-btn-fixed-bottom">
		<CloseTopbar />
		<b-loading :active="isLoading"></b-loading>
		<div v-if="mediator" class="columns is-mobile is-multiline is-variable is-0">
			<div class="column is-11 is-offset-0_5">
				<div class="b-steps st-steps">
					<nav class="steps is-black is-animated is-rounded">
						<ul class="step-items">
							<li
								class="step-item"
								:class="{'is-active': openStep >= 0, 'is-black': openStep==0, 'is-green': openStep > 0}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step1}}</span>
									</div>
									<div class="is-headline-m step-marker">1</div>
								</a>
							</li>
							<li
								class="step-item is-black"
								:class="{'is-active': openStep >= 1, 'is-black': openStep==1, 'is-green': openStep > 1}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step2}}</span>
									</div>
									<div class="is-headline-m step-marker">2</div>
								</a>
							</li>
							<li
								class="step-item is-black"
								:class="{'is-active': openStep >= 2, 'is-black': openStep==2, 'is-green': openStep > 2}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step3}}</span>
									</div>
									<div class="is-headline-m step-marker">3</div>
								</a>
							</li>
						</ul>
					</nav>

					<section class="step-content mt-4">
						<div class="step-item is-align-self-center" tabindex="0" v-show="openStep == 0">
							<h4 class="is-title-1-m has-text-white mb-8">{{t.wich}}</h4>
							<b-field class="has-text-white is-subhead-m" v-for="(proposal, i) in proposals" :key="i">
								<b-radio
									class="st-radio is-subhead-m"
									type="is-white"
									v-model="selectedProposal"
									:native-value="proposal"
								>{{$t("components.Proposals.ChooseProposal.thisFor", [proposal.plan, proposal.value.monthly])}}</b-radio>
							</b-field>
						</div>
						<div class="step-item is-align-self-center" tabindex="0" v-show="openStep == 1">
							<h4 class="is-title-1-m has-text-white mb-8">{{t.payment}}</h4>
							<div class="row" v-if="selectedProposal">
								<b-field v-for="(payment_split,name, i) in $t('payment_split')" class="has-text-white is-subhead-m col-sm-12 col-md-3" :key="i">
									<b-radio
										class="st-radio is-subhead-m"
										type="is-white"
										v-model="selectedPayment"
										:native-value="name"
									>{{payment_split + " (" + selectedProposal.value[name] +"€)"}}</b-radio>
								</b-field>
							</div>
						</div>

						<div class="step-item is-align-self-center" tabindex="0" v-show="openStep == 2">
							<h4 class="is-title-1-m has-text-white mb-8">{{t.ready}}</h4>
							<h4
								class="is-body-m has-text-white mb-8"
							>{{$t("components.Proposals.ChooseProposal.request", [mediator.name + " " + mediator.surname + " (" + mediator.business_name + ")"])}}</h4>

							<AutoData v-if="simulation.simulation_type=='car'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<HealthData v-if="simulation.simulation_type=='health'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<LifeData v-if="simulation.simulation_type=='life'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<LifeCreditData v-if="simulation.simulation_type=='life_credit'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<TeethData v-if="simulation.simulation_type=='teeth'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<PersonalAccidentData v-if="simulation.simulation_type=='personal_accident'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<TravelData v-if="simulation.simulation_type=='travel'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<BicycleData v-if="simulation.simulation_type=='bicycle'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<MotorcycleData v-if="simulation.simulation_type=='motorcycle'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<IndependentWorkData v-if="simulation.simulation_type=='independent_work'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<AnimalData v-if="simulation.simulation_type=='animal'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<WeaponsData v-if="simulation.simulation_type=='weapons'" class="mt-8" :simulation="simulation" :isCollapse="true" />
							<HouseData v-if="simulation.simulation_type=='house'" class="mt-8" :simulation="simulation" :isCollapse="true" />

							<ProposalData
								v-if="selectedProposal"
								:proposal="selectedProposal"
								:header="$t('insuranceTypes.' + selectedProposal.insurance_type) + ' (' + selectedProposal.plan + ')'"
								:isCollapse="true"
								:payment_split="selectedPayment"
								class="mt-8"
							/>
						</div>
						<div class="step-item" tabindex="-1" v-show="openStep == 3">
							<div class="columns is-mobile is-multiline">
								<div class="column is-12 has-text-centered mb-4">
									<div class="st-check-success has-text-white has-background-success">
										<b-icon icon="check" size="is-large"></b-icon>
									</div>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<h4 class="has-text-white is-title-1-m">{{t.insuranceDone}}</h4>
								</div>
								<div class="column is-offset-0_5 is-11 has-text-centered">
									<h4 class="has-text-white is-headline-m">{{t.text1}}</h4>
								</div>
								<div class="column is-offset-0_5 is-11 has-text-centered mb-8">
									<h4 class="has-text-white is-headline-m">{{t.text2}}</h4>
								</div>
								<!--<div class="column is-12 has-text-centered mb-4">
									<b-button
										expanded
										type="is-black"
										class="has-text-white is-headline-m"
										@click="$router.push({name: 'UserHome'})"
									>{{$t("shared.goHome")}}</b-button>
								</div>-->
							</div>
						</div>
					</section>
				</div>
			</div>
			<div v-if="openStep<maxStep" class="btn-fixed-bottom columns is-mobile">
				<div class="column is-5 is-offset-1">
					<b-button
						:disabled="openStep==minStep"
						expanded
						@click="goBack"
						type="is-black"
					>{{$t("shared.previous")}}</b-button>
				</div>
				<div class="column is-5">
					<b-button
						v-if="openStep==maxStep-1"
						expanded
						@click="onSubmit"
						type="is-black"
						:disabled="canGoNext"
					>{{$t("shared.confirm")}}</b-button>
					<b-button
						v-else
						expanded
						:disabled="canGoNext"
						@click="goForward"
						type="is-black"
					>{{$t("shared.next")}}</b-button>
				</div>
				<div :class="{'blur-blue': isMobile}"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import CloseTopbar from "../Shared/CloseTopbar.vue";
	import { getProposals, acceptProposal } from "@/api/proposals";
	import ProposalData from "@/components/Data/Proposal.vue";
	import AutoData from "@/components/Data/Auto";
	import HealthData from "@/components/Data/Health";
	import LifeData from "@/components/Data/Life";
	import LifeCreditData from "@/components/Data/LifeCredit";
	import TeethData from "@/components/Data/Teeth";
	import PersonalAccidentData from "@/components/Data/PersonalAccident";
	import TravelData from "@/components/Data/Travel";
	import BicycleData from "@/components/Data/Bicycle";
	import MotorcycleData from "@/components/Data/Motorcycle";
	import IndependentWorkData from "@/components/Data/IndependentWork";
	import AnimalData from "@/components/Data/Animal";
	import WeaponsData from "@/components/Data/Weapons";
	import HouseData from "@/components/Data/House";

	export default {
		name: "ChooseProposal",
		components: { 
			CloseTopbar,
			ProposalData,
			AutoData,
			HealthData,
			LifeData,
			LifeCreditData,
			TeethData,
			PersonalAccidentData,
			TravelData,
			BicycleData,
			MotorcycleData,
			IndependentWorkData,
			AnimalData,
			WeaponsData,
			HouseData
		},
		data() {
			return {
				t: this.$t("components.Proposals.ChooseProposal"),
				isCollapseOpen: false,
				proposals: [],
				mediator: null,
				simulation: null,
				isLoading: false,
				minStep: 0,
				maxStep: 3,
				openStep: 0,
				selectedProposal: null,
				selectedPayment: null,
			};
		},
		created() {
			this.getProposals();
		},
		methods: {
			getProposals() {
				this.isLoading = true;
				getProposals({
					mediator: this.$route.params.mediatorId,
					simulation: this.$route.params.simulationId,
					populate: "mediator simulation",
				})
					.then((response) => {
						this.proposals = response.data.data;
						this.simulation = this.proposals[0].simulation;
						this.mediator = this.proposals[0].mediator;
					})
					.catch((error) => {})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goForward() {
				if (this.openStep < this.maxStep) this.openStep++;
			},
			goBack() {
				if (this.openStep > this.minStep) this.openStep--;
			},
			onSubmit() {
				this.isLoading = true;
				acceptProposal(this.selectedProposal._id, this.selectedPayment)
					.then((response) => {
						this.goForward();
						setTimeout(() => this.$router.push({name:"Chat", params: { id: response.data.room }}), 5000);
					})
					.catch((error) => {
						this.$buefy.toast.open({
							message:
								error.response.data.code &&
								this.t.api[error.response.data.code]
									? this.t.api[error.response.data.code]
									: this.$t("shared.apiError"),
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
		computed: {
			canGoNext() {
				let can = false;
				let index = this.openStep + 1;

				switch (index) {
					case 1:
						if (this.selectedProposal) can = true;
						break;

					case 2:
						if (this.selectedPayment) can = true;
						break;
					
					case 3:
						can = true;
						break;

					default:
						break;
				}
				can = !can;
				return can;
			},
		},
	};
</script>

<style lang="scss" scoped>
	
</style>