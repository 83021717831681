<template>
	<div class="Car-Simulation">
		<div class="columns is-mobile is-multiline is-variable is-0">
			<div class="column is-11 is-offset-0_5">
				<div class="b-steps st-steps">
					<nav class="steps is-black is-animated is-rounded">
						<ul class="step-items">
							<li	
								v-show="!isMobile || openStep == 0"
								class="step-item is-black"
								:class="{'is-active': openStep >= 0, 'is-black': openStep==0, 'is-green': openStep > 0}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step2}}</span>
									</div>
									<div class="is-headline-m step-marker">1</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 1"
								class="step-item"
								:class="{'is-active': openStep >= 1, 'is-black': openStep==1, 'is-green': openStep > 1}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step1}}</span>
									</div>
									<div class="is-headline-m step-marker">2</div>
								</a>
							</li>
						</ul>
					</nav>
					<section class="step-content mt-4 pb-12">
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 0">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('brand') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.brand')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.brand"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('model') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.model')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.model"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('pack') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.version')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.pack"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('frame') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.frame')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.frame"
									></b-input>
								</b-field>
								<!--<b-field class="column is-12 is-caption-m has-text-white" :label="$t('fields.bicycle_use')">
								</b-field>
								<b-checkbox-button 
										class="column is-3-desktop is-12-mobile has-text-white pb-0 pt-0" 
										type="is-primary"
										v-for="(type, i) in t.bicycle_use" :key="100+i" 
										v-model="form.bicycle_use"
										:true-value="type"
										:native-value="type"
										@input="form.bicycle_use = type"
									>
									<span>{{type}}</span>
								</b-checkbox-button>
								<b-field class="column is-12 is-caption-m has-text-white" :label="$t('fields.bicycle_value')">
								</b-field>
								<b-checkbox-button 
										class="column is-3-desktop is-12-mobile has-text-white pb-0 pt-0" 
										type="is-primary"
										v-for="(type, i) in t.bicycle_value" :key="200+i" 
										v-model="form.value"
										:true-value="type"
										:native-value="type"
										@input="form.value = type"
									>
									<span>{{type}}</span>
								</b-checkbox-button>-->
								<b-field :type="invalids.includes('bicycle_type') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white" :label="$t('fields.bicycle_type')">
								</b-field>
								<b-checkbox-button 
										class="column is-3-desktop is-12-mobile has-text-white pb-0 pt-0" 
										type="is-primary"
										v-for="(type, i) in t.bicycle_type" :key="300+i" 
										v-model="form.type"
										:true-value="type"
										:native-value="type"
										@input="form.type = type"
									>
									<span>{{type}}</span>
								</b-checkbox-button>
								<b-field 
									v-if="form.type == t.bicycle_type[1] || form.type == t.bicycle_type[2]" 
									:type="invalids.includes('weight') ? 'is-error' : ''"
									class="column is-6 is-caption-m has-text-white" :label="$t('fields.weight')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.weight"
									></b-input>
								</b-field>
								<b-field 
									v-if="form.type == t.bicycle_type[1] || form.type == t.bicycle_type[2]" 
									:type="invalids.includes('power') ? 'is-error' : ''"
									class="column is-6 is-caption-m has-text-white" :label="$t('fields.power')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.power"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('payment_type') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.payment_type')">
									<b-checkbox 
										class="column is-3 has-text-white" 
										v-for="(option,name, i) in $t('payment_type')" :key="i" 
										v-model="form.payment_type"
										:true-value="name"
										:native-value="name"
									>
										{{option}}
									</b-checkbox>
								</b-field>
							</div>
						</div>

						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 1">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('name') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.name')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.name"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('surname') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.surname')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.surname"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('email') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white" :label="$t('fields.email')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="email"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.email"
									></b-input>
								</b-field>
								<b-field
									:type="invalidNif || invalids.includes('nif') ? 'is-error' : ''"
									:message="invalidNif ? t.errors.invalidNif : ''"
									class="column is-6-desktop is-12-mobile is-caption-m has-text-white"
									:label="$t('fields.nif')"
								>
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										maxlength="9"
										class="is-headline-m st-line-input has-text-white"
										@input="onNifInput"
										v-model="form.nif"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('birthdate') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.birthdate')">
									<b-datepicker
										v-model="form.birthdate"
										custom-class="p-0 h-6"
										class="is-headline-m st-datepicker has-text-white"
										:mobile-native="false"
										:max-date="new Date()"
										expanded
									></b-datepicker>
								</b-field>
								<b-field :type="invalids.includes('phone_number') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white" :label="$t('fields.phone')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.phone_number"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('address') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.address')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.address"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('postal_code') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.postal_code')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.postal_code"
									></b-input>
								</b-field>
							</div>
						</div>
						
						<div class="step-item" tabindex="-1" v-show="openStep == 2">
							<div class="columns is-mobile is-multiline">
								<div class="column is-12 has-text-centered mb-4">
									<div class="st-check-success has-text-white has-background-success">
										<b-icon icon="check" size="is-large"></b-icon>
									</div>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<h4 class="has-text-white is-title-1-m">{{t.submited}}</h4>
								</div>
								<div class="column is-offset-0_5 is-11 has-text-centered mb-8">
									<h4 class="has-text-white is-headline-m">{{t.wait}}</h4>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<b-button
										expanded
										type="is-black"
										class="has-text-white is-headline-m"
										@click="$router.push({name: 'UserHome'})"
									>{{$t("shared.goHome")}}</b-button>
								</div>
							</div>
						</div>
					</section>
					<nav v-if="openStep<maxStep" class="step-navigation">
						<div class="columns is-mobile">
							<div class="column is-6">
								<b-button
									:disabled="openStep==minStep"
									expanded
									@click="goBack"
									type="is-black"
								>{{$t("shared.previous")}}</b-button>
							</div>
							<div class="column is-6">
								<b-button
									v-if="openStep==maxStep-1"
									expanded
									@click="onSubmit"
									type="is-black"
									:disabled="canGoNext"
								>{{$t("shared.submit")}}</b-button>
								<b-button
									v-else
									expanded
									:disabled="canGoNext"
									@click="goForward"
									type="is-black"
								>{{$t("shared.next")}}</b-button>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>

		<b-loading v-model="isLoading"></b-loading>
	</div>
</template>

<script>
	import { addSimulation } from "@/api/simulations";
	import { validateNIF } from "@/utils/utils";

	export default {
		name: "BicycleForm",
		data() {
			return {
				simulationType: "bicycle",
				t: this.$t("components.Forms.Bicycle"),
				isLoading: false,
				invalids: [],
				minStep: 0,
				maxStep: 2,
				openStep: 0,
				invalidNif: false,
				form: {
					name: null,
					surname: null,
					email: null,
					phone_number: null,
					nif: null,
					birthdate: null,
					address: null,
					postal_code: null,
					type: null,
					brand:null,
					model:null,
					pack: null,
					frame:null,
					weight:null,
					power:null,
					payment_type: null
				},
			};
		},
		created() {
			if(this.getUser){
				this.form = {
					name: this.getUser.name,
					surname: this.getUser.surname,
					nif: this.getUser.nif,
					...(this.getUser.birthdate) && {birthdate: new Date(this.getUser.birthdate)},
					email: this.getUser.email,
					phone_number: this.getUser.phone,
					address: this.getUser.address,
					...(this.getUser.postal_code.number) && {postal_code: this.getUser.postal_code.number + "-" + this.getUser.postal_code.ext},
					bicycle_use: null,
					type: null,
					value: null
				}
				if(this.getUser.postal_code.number) this.form.postal_code = this.getUser.postal_code.number + "-" + this.getUser.postal_code.ext;
			}
		},
		methods: {
			onNifInput() {
				if (this.form.nif.length == 9)
					this.invalidNif = !validateNIF(this.form.nif);
			},
			onSubmit() {
				this.isLoading = true;
				let copy = Object.assign({}, this.form);
				addSimulation(this.simulationType, copy)
					.then((response) => {
						this.openStep++;
					})
					.catch((error) => {
						if (Array.isArray(error.response.data)) {
							error.response.data.forEach((element) => {
								this.invalids.push(element.param);
							});
          				}
						this.openStep = 0;
						this.$buefy.toast.open({
							message: this.t.api.error,
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goForward() {
				if (this.openStep < this.maxStep) this.openStep++;
			},
			goBack() {
				if (this.openStep > this.minStep) this.openStep--;
			}
		},
		computed: {
			canGoNext() {
				let can = false;
				let index = this.openStep + 1;

				switch (index) {
					case 1:
						if (
							this.form.type &&
							this.form.brand &&
							this.form.model &&
							this.form.pack &&
							this.form.frame &&
							this.form.payment_type
						){
							if(this.form.type == this.t.bicycle_type[1] || this.form.type == this.t.bicycle_type[2]){
								if(this.form.weight && this.form.power){
									can = true;
								}
							}else{
								can = true;
							}
						}	
			
							
						break;
					case 2:
						if (
							this.form.name &&
							this.form.surname &&
							this.form.birthdate &&
							!this.invalidNif &&
							this.form.email &&
							this.form.phone_number &&
							this.form.address &&
							this.form.postal_code 
						)
							can = true;
						break;

					default:
						break;
				}
				can = !can;
				return can;
			},
		},
	};
</script>

<style lang="scss" scoped>
	

	.column > div:first-of-type {
		position: relative;
	}
	.countries {
		justify-content: flex-start !important;
	}
</style>