import { render, staticRenderFns } from "./CompanyLife.vue?vue&type=template&id=22ca2d98&scoped=true&"
import script from "./CompanyLife.vue?vue&type=script&lang=js&"
export * from "./CompanyLife.vue?vue&type=script&lang=js&"
import style0 from "./CompanyLife.vue?vue&type=style&index=0&id=22ca2d98&lang=scss&scoped=true&"
import style1 from "./CompanyLife.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ca2d98",
  null
  
)

export default component.exports