<template>
  <div class="columns is-mobile is-multiline">
    <div class="column is-offset-1 is-10">
      <h4 class="is-title-1-m has-text-white">{{ t.title }}</h4>
    </div>
    <b-tabs
      id="settings-tab"
      position="is-centered"
      type="is-toggle"
      class="column is-offset-1 is-10 mb-12"
      v-model="activeTab"
      expanded
    >
      <b-tab-item
        class="st-tab-item columns is-mobile is-multiline"
        id="individual"
        value="individual"
        label="Individual"
      >
        <div
          class="column is-4-desktop is-6-touch"
          v-for="(insurance, i) in insuranceTypes.individual"
          :key="i"
        >
          <TypeCard
            @click.native="onSelectedType(insurance)"
            :isBlurred="
              (typeSelected && typeSelected.name != insurance.name) ||
                !typeSelected
            "
            :text="insurance.name"
            :img="insurance.img"
          />
        </div>
      </b-tab-item>
      <b-tab-item
        class="st-tab-item columns is-mobile is-multiline"
        id="companies"
        value="companies"
        label="Empresas"
      >
        <div
          class="column is-4-desktop is-6-touch"
          v-for="(insurance, i) in insuranceTypes.company"
          :key="i"
        >
          <TypeCard
            @click.native="onSelectedType(insurance)"
            :isBlurred="
              (typeSelected && typeSelected.name != insurance.name) ||
                !typeSelected
            "
            :text="insurance.name"
            :img="insurance.img"
          />
        </div>
      </b-tab-item>
    </b-tabs>
    <!-- <div class="column is-offset-1 is-10">
      <b-button
        @click="goToSelected"
        :disabled="typeSelected == null"
        type="is-black"
        expanded
        >{{ $t("shared.next") }}</b-button
      >
    </div> -->
  </div>
</template>

<script>
import TypeCard from "@/components/LandingPage/TypeCard";
import insuranceTypes from "@/config/insuranceTypes";

export default {
  name: "ChooseSimulation",
  components: { TypeCard },
  data() {
    return {
      t: this.$t("components.Forms.Choose"),
      typeSelected: null,
      insuranceTypes,
      activeTab: "individual",
    };
  },
  created() {

  },
  methods: {
    onSelectedType(i) {
      this.typeSelected = i;
      this.goToSelected();
    },
    goToSelected() {
      window.scrollTo(0,0);
      this.$router.push({
        name: this.typeSelected.route,
      });
    },
  },
};
</script>

<style>
#settings-tab nav ul li a {
  background-color: #fff;
  color: #1981fc;
}
#settings-tab .tabs.is-toggle li.is-active a {
  background-color: transparent !important;
  border-color: #dbdbdb;
  color: #fafafa;
}
</style>
