<template>
	<div class="mt-8">
		<!--<b-loading :active="isLoading"></b-loading>-->
		<div class="columns is-mobile">
			<div class="column is-offset-1 is-10 level is-mobile">
				<h4 class="is-headline-m-m is-big-title-m-d level-left">{{t.insurances}}</h4>
				<a v-if="!see_all && !isMobile" class="is-caption-m level-right-desktop" @click="see_all=true">{{$t("shared.see_all")}}</a>
				<a v-else-if="!isMobile" class="is-caption-m level-right-desktop" @click="see_all=false">{{$t("shared.see_less")}}</a>
			</div>
		</div>
		<div v-if="no_data" class="colums is-mobile">
			<div class="column is-offset-1 is-10 is-mobile">
				<p class="has-text-black is-body-m-m has-text-centered">
					{{t.no_data}}
				</p>
			</div>
		</div>
		<div v-if="see_all || isMobile" class="columns is-mobile is-multiline" :class="{'ml-8':!isMobile, 'is-centered':isMobile}">
			<div v-for="(insurance, i) in proposals"
					:key="i" class="column is-4-fullhd is-6-desktop is-8-mobile">
				<Card
					@click.native="goToInsurance(insurance)"
					:slot1="slot1[i]"
					:slot2="slot2[i]"
					:slot3="slot3[i]"
					:slot4="slot4[i]"
				/>
			</div>
		</div>
		<div v-else class="columns is-mobile is-multiline">
			<div class="column is-offset-1 is-10 card-row">
				<Card
					@click.native="goToInsurance(insurance)"
					v-for="(insurance, i) in proposals"
					:key="i"
					:slot1="slot1[i]"
					:slot2="slot2[i]"
					:slot3="slot3[i]"
					:slot4="slot4[i]"
				/>
			</div>
		</div>

	</div>
</template>

<script>
	import { getProposals } from "@/api/proposals";

	import Card from "@/components/Insurances/Card";

	export default {
		name: "MyInsurances",
		components: { Card },
		data() {
			return {
				isLoading: false,
				proposals: [],
				t: this.$t("components.Home.MyInsurances"),
				slot1: [],
				slot2: [],
				slot3: [],
				slot4: [],
				see_all: false,
				no_data: false
			};
		},
		created() {
			this.getProposals();
		},
		methods: {
			getProposals() {
				this.isLoading = true;
				let query = {
					accept: true,
					populate: "simulation"
				}
				getProposals(query)
					.then((response) => {
						this.proposals = response.data.data;
						this.proposals.forEach(insurance => {
							this.slot1.push(this.$t('insuranceTypes.' + insurance.insurance_type));
							this.slot2.push(insurance.plan);
							this.slot4.push(insurance.room);
							
							if (insurance.insurance_type == 'car'){
								this.slot3.push(insurance.simulation.data.car.plate)
							} else if (insurance.insurance_type == 'house') {
								this.slot3.push(insurance.simulation.data.house_postal_code);
							}  else if (insurance.insurance_type == 'motorcycle'){
								this.slot3.push(insurance.simulation.data.motorcycle.plate)
							} else {
								this.slot3.push("");
							}
						});
					})
					.catch((error) => {
						console.log(error);
						this.no_data = true;
						/* this.$buefy.toast.open({
							message: this.t.no_data,
							position: "is-bottom",
							type: "is-error",
						}); */
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goToInsurance(ins) {
				this.$router.push({name: 'InsuranceID', params: {id: ins._id}})
			}
		},
	};
</script>

<style lang="scss" scoped>
	
</style>