<template>
	<div class="Car-Simulation">
		<div class="columns is-mobile is-multiline is-variable is-0">
			<div class="column is-11 is-offset-0_5">
				<div class="b-steps st-steps">
					<nav class="steps is-black is-animated is-rounded">
						<ul class="step-items">
							<li	
								v-show="!isMobile || openStep == 0"
								class="step-item"
								:class="{'is-active': openStep >= 0, 'is-black': openStep==0, 'is-green': openStep > 0}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step1}}</span>
									</div>
									<div class="is-headline-m step-marker">1</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 0 || openStep == 1"
								class="step-item is-black"
								:class="{'is-active': openStep >= 1, 'is-black': openStep==1, 'is-green': openStep > 1}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step2}}</span>
									</div>
									<div class="is-headline-m step-marker">2</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 1 || openStep == 2"
								class="step-item is-black"
								:class="{'is-active': openStep >= 2, 'is-black': openStep==2, 'is-green': openStep > 2}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step3}}</span>
									</div>
									<div class="is-headline-m step-marker">3</div>
								</a>
							</li>
						</ul>
					</nav>
					<section class="step-content mt-4 pb-12">
						<div class="step-items" tabindex="-1" v-show="openStep == 0">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('cae') ? 'is-error' : ''" class="column is-7-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.cae')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.cae"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('persons') ? 'is-error' : ''" class="column is-7 is-caption-m has-text-white" :label="$t('fields.persons')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.persons"
										:lazy="true"
										@input="addPerson"
									></b-input>
								</b-field>
							</div>
							<div v-for="(person,index) in form.persons_data" :key="index" class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('persons_data['+index+'].birthdate') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.birthdate')">
									<b-datepicker
										v-model="form.persons_data[index].birthdate"
										custom-class="p-0 h-6"
										class="is-headline-m st-datepicker has-text-white"
										:mobile-native="false"
										:max-date="new Date()"
										expanded
									></b-datepicker>
								</b-field>
								<b-field :type="invalids.includes('persons_data['+index+'].salary') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.salary')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.persons_data[index].salary"
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 1">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('name') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.name')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.name"
									></b-input>
								</b-field>
								<b-field
									:type="invalidNipc ? 'is-error' : ''"
									:message="invalidNipc ? t.errors.invalidNipc : ''"
									class="column is-6-desktop is-12-mobile is-caption-m has-text-white"
									:label="$t('fields.nipc')"
								>
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										maxlength="9"
										class="is-headline-m st-line-input has-text-white"
										@input="onNipcInput"
										v-model="form.nipc"
									></b-input>
								</b-field>
								<b-field
									:type="invalids.includes('name') ? 'is-error' : ''"
									class="column is-6-desktopis-12-mobile is-caption-m has-text-white"
									:label="$t('fields.address')"
								>
									<b-input
										custom-class="p-0 h-6"
										class="is-headline-m st-line-input has-text-white"
										outlined
										autocomplete="on"
										name="address"
										type="text"
										v-model="form.address"
									></b-input>
								</b-field>
								<b-field
									:type="invalids.includes('postal_code') ? 'is-error' : ''"
									class="column is-6-desktopis-12-mobile is-caption-m has-text-white"
									:label="$t('fields.postal_code')"
								>
									<b-input
										custom-class="p-0 h-6"
										class="is-headline-m st-line-input has-text-white"
										outlined
										autocomplete="on"
										name="address"
										type="text"
										v-model="form.postal_code"
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 2">
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('payment_type') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.payment_type')">
								</b-field>
								<b-checkbox-button 
										class="column is-6-desktop is-12-mobile has-text-white" 
										v-for="(option,name, i) in $t('payment_type')" :key="i" 
										v-model="form.payment_type"
										:true-value="name"
										:native-value="name"
										@input="form.payment_type = name"
									>
									{{option}}
								</b-checkbox-button>
							</div>
						</div>
						<div class="step-item" tabindex="-1" v-show="openStep == 3">
							<div class="columns is-mobile is-multiline">
								<div class="column is-12 has-text-centered mb-4">
									<div class="st-check-success has-text-white has-background-success">
										<b-icon icon="check" size="is-large"></b-icon>
									</div>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<h4 class="has-text-white is-title-1-m">{{t.submited}}</h4>
								</div>
								<div class="column is-offset-0_5 is-11 has-text-centered mb-8">
									<h4 class="has-text-white is-headline-m">{{t.wait}}</h4>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<b-button
										expanded
										type="is-black"
										class="has-text-white is-headline-m"
										@click="$router.push({name: 'UserHome'})"
									>{{$t("shared.goHome")}}</b-button>
								</div>
							</div>
						</div>
					</section>
					<nav v-if="openStep<maxStep" class="step-navigation">
						<div class="columns is-mobile">
							<div class="column is-6">
								<b-button
									:disabled="openStep==minStep"
									expanded
									@click="goBack"
									type="is-black"
								>{{$t("shared.previous")}}</b-button>
							</div>
							<div class="column is-6">
								<b-button
									v-if="openStep==maxStep-1"
									expanded
									@click="onSubmit"
									type="is-black"
									:disabled="canGoNext"
								>{{$t("shared.submit")}}</b-button>
								<b-button
									v-else
									expanded
									:disabled="canGoNext"
									@click="goForward"
									type="is-black"
								>{{$t("shared.next")}}</b-button>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>

		<b-loading v-model="isLoading"></b-loading>
	</div>
</template>

<script>
	import { addSimulation } from "@/api/simulations";
	import { validateNIF } from "@/utils/utils";

	export default {
		name: "CompanyHealthForm",
		data() {
			return {
				simulationType: "company_life",
				t: this.$t("components.Forms.CompanyLife"),
				isLoading: false,
				invalids:[],
				minStep: 0,
				maxStep: 3,
				openStep: 0,
				invalidNipc: false,
				form: {
					name: null,
					nipc: null,
					postal_code: null,
					address: null,
					cae: null,
					persons: 0,
					_persons: 0,
					persons_data: [],				
					informations:null,
					payment_type: null
				},
			};
		},
		created() {
			if(this.getUser){
				this.form.name = this.getUser.name;
				this.form.nipc = this.getUser.nif;
				if(this.getUser.postal_code.number) this.form.postal_code = this.getUser.postal_code.number + "-" + this.getUser.postal_code.ext;
				this.form.address = this.getUser.address;
			}
		},
		methods: {
			addPerson(){
				if(this.form.persons){
					if(this.form.persons > this.form._persons){
						while(this.form.persons > this.form._persons){
							this.form.persons_data.push({
								birthdate: null,
								salary: null
							});
							this.form._persons = this.form._persons + 1;
						}
						
					} else if(this.form.persons < this.form._persons){
						this.form.persons_data.splice(this.form.persons);
						this.form._persons = this.form.persons;
					}
				}
			},
			onNipcInput() {
				if (this.form.nipc.length == 9)
					this.invalidNipc = !validateNIF(this.form.nipc);
			},
			onSubmit() {
				this.isLoading = true;
				let copy = Object.assign({}, this.form);
				addSimulation(this.simulationType, copy)
					.then((response) => {
						this.openStep++;
					})
					.catch((error) => {
						if (Array.isArray(error.response.data)) {
							error.response.data.forEach((element) => {
								this.invalids.push(element.param);
							});
          				}
						this.openStep = 0;
						this.$buefy.toast.open({
							message: this.t.api.error,
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goForward() {
				if (this.openStep < this.maxStep) this.openStep++;
			},
			goBack() {
				if (this.openStep > this.minStep) this.openStep--;
			},
		},
		computed: {
			canGoNext() {
				let can = false;
				let index = this.openStep + 1;

				switch (index) {
					case 1:
						if (
							this.form.cae &&
							this.form.persons_data.length == this.form.persons
						){	
							let checkerBirthdate = arr => arr.every(v => v.birthdate);
							let checkerSalary = arr => arr.every(v => v.salary);

							if(checkerBirthdate(this.form.persons_data) && checkerSalary(this.form.persons_data))
							 can = true;
						} 
						break;

					case 2:
						if (
							this.form.name &&
							!this.invalidNipc &&
							this.form.postal_code &&
							this.form.address
						)
							can = true;
						break;

					case 3:
						if (
							this.form.payment_type
						)
						can = true;
						 
						break;
					default:
						break;
				}
				can = !can;
				return can;
			},
		},
	};
</script>

<style lang="scss" scoped>
	

	.column > div:first-of-type {
		position: relative;
	}
</style>
<style>
	.b-checkbox.checkbox[data-v-6f91f2e8]:not(.button) {
		margin-right: 0;
	}
</style>