<template>
  <div
    class="has-btn-fixed-bottom has-background-gray-light is-full-viewport-height"
  >
    <b-loading :active="isLoading"></b-loading>
    <div class="has-background-blue columns is-mobile is-multiline pb-8">
      <div class="column is-12-touch is-10-desktop is-offset-1-desktop pb-0">
        <b-button
          type="is-ghost"
          class="has-text-white is-body-m has-icon-margin"
          icon-pack="st"
          icon-left="arrow-left"
          @click="$router.go(-1)"
          >{{ $t("shared.goBack") }}</b-button
        >
      </div>
      <div class="column is-10 is-offset-1 has-text-white level is-mobile">
        <h3 class="level-left is-title-1-m">{{ t.proposal }}</h3>
        <h3 class="level-right is-body-m">
          {{
            simulation ? $t(`insuranceTypes.${simulation.simulation_type}`) : ""
          }}
        </h3>
      </div>
    </div>
    <div class="columns is-mobile is-multiline mt-16">
      <div
        class="column has-background-white is-10-touch is-offset-1-touch is-3-desktop is-offset-1-desktop pl-6 pr-6 st-card"
        :class="isCollapseOpen ? 'py-6' : 'py-1'"
      >
        <b-collapse :open.sync="isCollapseOpen" aria-id="proposalData">
          <template #trigger>
            <div class="columns is-mobile">
              <div class="is-align-self-center column is-8">
                <h1 class="is-headline-m">{{ mediator.business_name }}</h1>
              </div>
              <div class="column is-4">
                <b-button
                  size="is-large"
                  icon-pack="st"
                  :icon-right="
                    !isCollapseOpen ? 'arrow-down-circle' : 'arrow-up-circle'
                  "
                  class="no-focus float-right"
                  type="is-ghost"
                  aria-controls="proposalData"
                />
              </div>
            </div>
          </template>
          <div class="columns is-mobile is-multiline">
            <div class="column is-12">
              <p class="is-subhead-m has-text-gray">
                {{ t.mediatorData.name }}
              </p>
              <p class="is-headline-m has-text-black">
                {{ mediator.name }} {{ mediator.surname }}
              </p>
            </div>
            <div class="column is-12">
              <p class="is-subhead-m has-text-gray">
                {{ t.mediatorData.email }}
              </p>
              <p class="is-headline-m has-text-black">{{ mediator.email }}</p>
            </div>
            <div class="column is-12">
              <p class="is-subhead-m has-text-gray">
                {{ t.mediatorData.postal_code }}
              </p>
              <p class="is-headline-m has-text-black">
                {{ mediator.postal_code.number }}-{{ mediator.postal_code.ext }}
              </p>
            </div>
            <div v-if="mediator.nif" class="column is-12">
              <p class="is-subhead-m has-text-gray">
                {{ t.mediatorData.nipc }}
              </p>
              <p class="is-headline-m has-text-black">
                {{ mediator.nif }}
              </p>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        class="column is-10-touch is-offset-1-touch is-6-desktop is-offset-1-desktop mt-6"
      >
        <div class="columns is-mobile is-multiline">
          <div class="column is-12 level is-mobile mb-0">
            <h3 class="is-headline-m level-left">{{ t.details }}</h3>
          </div>
          <div class="column is-12">
            <b-table
              class="st-table has-text-black has-background-gray-light"
              :hoverable="true"
              :mobile-cards="false"
              :data="getData"
              :columns="getColumns"
              :sticky-header="true"
            ></b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-fixed-bottom columns is-mobile">
      <div
        class="column is-offset-1-touch is-10-touch is-offset-4-desktop is-4-desktop is-offset-5-fullhd is-2-fullhd"
      >
        <b-button
          expanded
          type="is-blue"
          @click="$router.push({ name: 'ChooseProposal' })"
          >{{ t.chooseOne }}</b-button
        >
      </div>
      <div :class="{ 'blur-gray-light': isMobile }"></div>
    </div>
  </div>
</template>

<script>
import { getProposals } from "@/api/proposals";

export default {
  name: "ProposalID",
  data() {
    return {
      t: this.$t("components.Proposals.ProposalID"),
      proposals: [],
      iconIncludes:
        '<span class="icon is-medium has-text-blue"><i class="st st-tick"></i></span>',
      iconDoesntInclude:
        '<span class="icon is-medium has-text-red"><i class="st st-close"></i></span>',
      simulation: null,
      mediator: null,
      isLoading: false,
      isCollapseOpen: this.isMobile ? false : true,
      locations: [],
    };
  },
  created() {
    this.getProposals();
  },
  methods: {
    getProposals() {
      this.isLoading = true;
      getProposals({
        mediator: this.$route.params.mediatorId,
        simulation: this.$route.params.simulationId,
        populate: "mediator simulation",
      })
        .then((response) => {
          this.proposals = response.data.data;
          console.log(this.proposals);

          this.simulation = this.proposals[0].simulation;
          this.mediator = this.proposals[0].mediator;
        })
        .catch((error) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    getData() {
      let data = [];

      for (let i = 0; i < this.proposals.length; i++) {
        let values = {};
        values.description = this.proposals[i].plan;
        for (let key in this.$t("payment_split")) {
          values[key] = this.proposals[i].value[key];
        }
        data.push(values);
      }
	  data.push({});

      return data;
    },
    getColumns() {
      let columns = [
        {
          field: "description",
          sticky: true,
          headerClass: "is-subhead-m",
          width: "135px",
        },
      ];
      for (let key in this.$t("payment_split")) {
        columns.push({
          field: key,
          label: this.$t("payment_split")[key],
          headerClass: "is-subhead-m",
          centered: true,
        });
      }
      return columns;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/all";
@import "~bulma/sass/utilities/mixins.sass";

.st-card {
  @include from($touch) {
    height: fit-content;
  }
}
</style>
