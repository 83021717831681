<template>
	<div class="has-text-centered">
		<img  class="choose_img" :src="img" :alt="text" />
		<h3 class="has-text-white has-text-centered is-title-2-d-d is-headline-m-m">{{t[text]}}</h3>
	</div>
</template>

<script>
	export default {
		name: "Card",
		props: {
			text: {
				type: String,
				default: "car",
			},
			img: {
				type: String,
				default: "",
			},
			isBlurred: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				t: this.$t("insuranceTypes"),
			};
		},
	};
</script>

<style lang="scss" scoped>
	div {
		position: relative;
	}

	h3 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.blur {
		-webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
		filter: blur(5px);
	}
	.choose_img{
		height: 10rem;
		width: 100%;
		object-fit: cover;
		text-shadow: 1px 1px #1d1e1e;
	}
</style>