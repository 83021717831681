<template>
	<div class="b-steps is-small is-vertical">
		<nav class="steps is-blue has-label-right is-animated is-rounded">
			<ul class="step-items">
				<li class="step-item is-blue" v-for="(step, i) in steps" :key="i">
					<a class="step-link">
						<div class="step-marker"></div>
						<div class="step-details ml-4 is-caption-m-m is-body-m-d level is-mobile">
							<span class="level-left has-text-black">{{step.left}}</span>
							<span class="level-right has-text-gray-darker">{{step.right}}</span>
						</div>
					</a>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
	export default {
		name: "Steps",
		props: {
			steps: {
				type: Array,
				default: () => {
					return [];
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/variables/all";

	.b-steps > .steps {
		width: 100%;
	}
	.step-link {
		width: 100%;
		justify-content: left !important;
		cursor: unset !important;
	}
	.step-details {
		width: 89%;
	}

	.step-marker {
		background-color: $blue !important;
		border: none !important;
		height: 1rem !important;
		width: 1rem !important;
	}
	.b-steps.is-small.is-vertical
		> .steps.has-label-right
		.step-items
		.step-item:not(:first-child)::before {
		left: calc(0.5rem - 0.1em) !important;
	}

	.b-steps.is-vertical > .steps .step-items .step-item {
		padding: 1.5rem 0 !important;
	}
</style>