<template>
	<div class="Car-Simulation">
		<div class="columns is-mobile is-multiline is-variable is-0">
			<div class="column is-11 is-offset-0_5">
				<div class="b-steps st-steps">
					<nav class="steps is-black is-animated is-rounded">
						<ul class="step-items">
							<li	
								v-show="!isMobile || openStep == 0"
								class="step-item"
								:class="{'is-active': openStep >= 0, 'is-black': openStep==0, 'is-green': openStep > 0}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step1}}</span>
									</div>
									<div class="is-headline-m step-marker">1</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 1"
								class="step-item is-black"
								:class="{'is-active': openStep >= 1, 'is-black': openStep==1, 'is-green': openStep > 1}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step2}}</span>
									</div>
									<div class="is-headline-m step-marker">2</div>
								</a>
							</li>
							<li
								v-show="!isMobile || openStep == 2"
								class="step-item is-black"
								:class="{'is-active': openStep >= 1, 'is-black': openStep==2, 'is-green': openStep > 2}"
							>
								<a class="step-link">
									<div class="step-details mb-1">
										<span class="is-headline-m has-text-white">{{t.step3}}</span>
									</div>
									<div class="is-headline-m step-marker">3</div>
								</a>
							</li>
						</ul>
					</nav>
					<section class="step-content mt-4 pb-12">
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 0">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.new_address')">
								</b-field>
								<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(option, i) in $t('trueFalse')" :key="i" 
										v-model="new_address"
										:native-value="!option.value"
										@input="newAddress(!option.value)"
									>
										{{option.label}}
								</b-checkbox-button>
							</div>
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('house_address') ? 'is-error' : ''" v-if="new_address" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.address')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.house_address"
									></b-input>
								</b-field>
								<b-field
									:type="invalids.includes('house_postal_code') ? 'is-error' : ''"
									v-if="new_address"
									class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.postal_code')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.house_postal_code"
									></b-input>
								</b-field>
								<b-field
									:type="invalids.includes('house_value') ? 'is-error' : ''"
									class="column is-12 is-caption-m has-text-white" :label="$t('fields.house_value')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.house_value"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('house_filling') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.house_filling')">
								</b-field>
								<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(option, i) in $t('trueFalse')" :key="i" 
										v-model="form.house_filling"
										:native-value="option.value"
										@input="checkFilling(option.value)"
									>
										{{option.label}}
								</b-checkbox-button>
								<b-field 
									:type="invalids.includes('house_filling_value') ? 'is-error' : ''"
									v-if="form.house_filling"
									class="column is-12 is-caption-m has-text-white" 
									:label="$t('fields.house_filling_value')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.house_filling_value"
									></b-input>
								</b-field>
								<b-field 
									:type="invalids.includes('coverages') ? 'is-error' : ''"
									class="column is-12 is-caption-m has-text-white mb-0" 
									:label="$t('fields.coverages')">
								</b-field>
								<b-checkbox
										v-for="(option, i) in t.coverages" :key="100+i" 
										class="column is-3-desktop is-6-mobile is-headline-m st-line-input has-text-white"
										v-model="form.coverages"
										:native-value="option"
								>{{option}}</b-checkbox>
								<div class="column is-12"></div>
								<b-field 
									v-if="form.coverages.includes(t.coverages[3])"
									class="column is-12 is-caption-m has-text-white" 
									:label="$t('fields.car_value')"
									:type="invalids.includes('car_value') ? 'is-error' : ''">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.car_value"
									></b-input>
								</b-field>
								<b-field 
									:type="invalids.includes('informatic_value') ? 'is-error' : ''"
									v-if="form.coverages.includes(t.coverages[6])"
									class="column is-12 is-caption-m has-text-white" 
									:label="$t('fields.informatic_value')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.informatic_value"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('construction_year') ? 'is-error' : ''" class="column is-12-mobile is-6-desktop is-caption-m has-text-white" :label="$t('fields.construction_year')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										min="1900"
                						:max="new Date().getFullYear()"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.construction_year"
									></b-input>
								</b-field>
								<b-field 
									class="column is-12-mobile is-6-desktop is-caption-m has-text-white" 
									:label="$t('fields.size')"
									:type="invalids.includes('size') ? 'is-error' : ''">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.size"
									></b-input>
								</b-field>
								<b-field 
									:type="invalids.includes('wc_quantity') ? 'is-error' : ''"
									class="column is-12-mobile is-6-desktop is-caption-m has-text-white" 
									:label="$t('fields.wc_quantity')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.wc_quantity"
									></b-input>
								</b-field>
								<b-field 
									:type="invalids.includes('roooms_number') ? 'is-error' : ''"
									class="column is-12-mobile is-6-desktop is-caption-m has-text-white" 
									:label="$t('fields.rooms_number')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.rooms_number"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('canalization') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.canalization')">
								</b-field>
								<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(option, i) in $t('trueFalse')" :key="200+i" 
										v-model="form.canalization"
										:native-value="option.value"
										@input="form.canalization=option.value"
									>
										{{option.label}}
								</b-checkbox-button>
								<b-field 
									:type="invalids.includes('canalization_replacement') ? 'is-error' : ''"
									v-if="form.canalization"
									class="column is-12 is-caption-m has-text-white" 
									:label="$t('fields.canalization_replacement')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="number"
										min="1900"
                						:max="new Date().getFullYear()"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.canalization_replacement"
									></b-input>
								</b-field>
							</div>
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field 
									:type="invalids.includes('habitation_type') ? 'is-error' : ''"
									class="column is-4-desktop is-12-mobile is-caption-m has-text-white mb-0" 
									:label="$t('fields.habitation_type')">
									<b-select v-model="form.habitation_type" expanded>
										<option
										 v-for="(type,i) in t.types" 
										 :key="500+i"
										 >{{type}}</option>
									</b-select>
								</b-field>
								<b-field 
									:type="invalids.includes('habitation_typology') ? 'is-error' : ''"
									v-if="form.habitation_type && form.habitation_type.includes(t.types[0])"
									class="column is-4-desktop is-12-mobile is-caption-m has-text-white mb-0" 
									:label="$t('fields.habitation_typology.apartment')">
									<b-select v-model="form.habitation_typology" expanded>
										<option
										 v-for="(type,i) in 5" 
										 :key="300+i"
										 >{{"T"+i}}</option>
									</b-select>
								</b-field>
								<b-field 
									:type="invalids.includes('habitation_typology') ? 'is-error' : ''"
									v-if="form.habitation_type && form.habitation_type.includes(t.types[1])"
									class="column is-4-desktop is-12-mobile is-caption-m has-text-white mb-0" 
									:label="$t('fields.habitation_typology.habitation')">
									<b-select v-model="form.habitation_typology" expanded>
										<option
										 v-for="(type,i) in 5" 
										 :key="400+i"
										 >{{"V"+i}}</option>
									</b-select>
								</b-field>
								<b-field 
									:type="invalids.includes('habitation_floor') ? 'is-error' : ''"
									v-if="form.habitation_type && form.habitation_type.includes(t.types[0])"
									class="column is-4-desktop is-12-mobile is-caption-m has-text-white mb-0" 
									:label="$t('fields.habitation_floor')">
									<b-select v-model="form.habitation_floor" expanded>
										<option
										 v-for="(floor,i) in t.floor" 
										 :key="600+i"
										 >{{floor}}</option>
									</b-select>
								</b-field>
								<b-field 
									:type="invalids.includes('security') ? 'is-error' : ''"
									class="column is-12 is-caption-m has-text-white mb-0" 
									:label="$t('fields.security')"></b-field>
								<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(option, i) in t.security" :key="700+i" 
										v-model="form.security"
										:native-value="option"
										@input="securityInput(option)"
										:disabled="form.security[0] == t.security[t.security.length-1] && option != form.security[0]"
									>
										{{option}}
								</b-checkbox-button>
								<b-field 
									:type="invalids.includes('house_use') ? 'is-error' : ''"
									class="column is-12 is-caption-m has-text-white mb-0"
									:label="$t('fields.house_use')"></b-field>
									<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(option, i) in t.house_use" :key="800+i" 
										v-model="form.house_use"
										:native-value="option"
										@input="form.house_use = option"
									>
										{{option}}
								</b-checkbox-button>
								<b-field 
									:type="invalids.includes('ownership') ? 'is-error' : ''"
									class="column is-12 is-caption-m has-text-white mb-0"
									:label="$t('fields.ownership')"></b-field>
									<b-checkbox-button
										class="column is-3-desktop is-12-mobile has-text-white" 
										v-for="(option, i) in t.ownership" :key="900+i" 
										v-model="form.ownership"
										:native-value="option"
										@input="form.ownership = option"
									>
										{{option}}
								</b-checkbox-button>
								<p class="is-caption-m has-text-white mt-4">{{t.rooms_info}}</p>
							</div>
						</div>
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 1">
							<h4
								class="is-title-1-m has-text-white mb-7"
							>{{t.aboutYou}}</h4>

							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('name') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.name')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.name"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('surname') ? 'is-error' : ''" class="column is-6 is-caption-m has-text-white" :label="$t('fields.surname')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.surname"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('birthdate') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.birthdate')">
									<b-datepicker
										v-model="form.birthdate"
										custom-class="p-0 h-6"
										class="is-headline-m st-datepicker has-text-white"
										:mobile-native="false"
										:max-date="new Date()"
										expanded
									></b-datepicker>
								</b-field>
								<b-field
									:type="invalidNif ? 'is-error' : ''"
									:message="invalidNif ? t.errors.invalidNif : ''"
									class="column is-6-desktop is-12-mobile is-caption-m has-text-white mb-0"
									:label="$t('fields.nif')"
								>
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										maxlength="9"
										class="is-headline-m st-line-input has-text-white"
										@input="onNifInput"
										v-model="form.nif"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('address') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.address')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.address"
									></b-input>
								</b-field>
								<b-field :type="invalids.includes('postal_code') ? 'is-error' : ''" class="column is-6-desktop is-12-mobile is-caption-m has-text-white" :label="$t('fields.postal_code')">
									<b-input
										custom-class="p-0 h-6"
										outlined
										type="text"
										class="is-headline-m st-line-input has-text-white"
										v-model="form.postal_code"
									></b-input>
								</b-field>
							</div>
						</div>
						
						<div class="step-item is-align-self-center" tabindex="-1" v-show="openStep == 2">
							<div class="columns is-mobile is-multiline is-variable is-2">
								<b-field :type="invalids.includes('payment_type') ? 'is-error' : ''" class="column is-12 is-caption-m has-text-white mb-0" :label="$t('fields.payment_type')">
								</b-field>
								<b-checkbox-button 
										class="column is-6-desktop is-12-mobile has-text-white" 
										v-for="(option,name, i) in $t('payment_type')" :key="i" 
										v-model="form.payment_type"
										:true-value="name"
										:native-value="name"
										@input="form.payment_type = name"
									>
									{{option}}
								</b-checkbox-button>
							</div>
						</div>
						<div class="step-item" tabindex="-1" v-show="openStep == 3">
							<div class="columns is-mobile is-multiline">
								<div class="column is-12 has-text-centered mb-4">
									<div class="st-check-success has-text-white has-background-success">
										<b-icon icon="check" size="is-large"></b-icon>
									</div>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<h4 class="has-text-white is-title-1-m">{{t.submited}}</h4>
								</div>
								<div class="column is-offset-0_5 is-11 has-text-centered mb-8">
									<h4 class="has-text-white is-headline-m">{{t.wait}}</h4>
								</div>
								<div class="column is-12 has-text-centered mb-4">
									<b-button
										expanded
										type="is-black"
										class="has-text-white is-headline-m"
										@click="$router.push({name: 'UserHome'})"
									>{{$t("shared.goHome")}}</b-button>
								</div>
							</div>
						</div>
					</section>
					<nav v-if="openStep<maxStep" class="step-navigation">
						<div class="columns is-mobile">
							<div class="column is-6">
								<b-button
									:disabled="openStep==minStep"
									expanded
									@click="goBack"
									type="is-black"
								>{{$t("shared.previous")}}</b-button>
							</div>
							<div class="column is-6">
								<b-button
									v-if="openStep==maxStep-1"
									expanded
									@click="onSubmit"
									type="is-black"
									:disabled="canGoNext"
								>{{$t("shared.submit")}}</b-button>
								<b-button
									v-else
									expanded
									:disabled="canGoNext"
									@click="goForward"
									type="is-black"
								>{{$t("shared.next")}}</b-button>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>

		<b-loading v-model="isLoading"></b-loading>
	</div>
</template>

<script>
	import { addSimulation } from "@/api/simulations";
	import { validateNIF } from "@/utils/utils";

	export default {
		name: "HouseForm",
		data() {
			return {
				simulationType: "house",
				t: this.$t("components.Forms.House"),
				months: this.$t("months"),
				years: [],
				invalids: [],
				isLoading: false,
				minStep: 0,
				maxStep: 3,
				openStep: 0,
				invalidNif: false,
				new_address:null,
				form: {
					name: null,
					surname: null,
					nif: null,
					birthdate: null,
					postal_code: null,
					address: null,

					house_value: null,
					house_filling: null,
					house_filling_value: null,
					house_address: null,
					house_postal_code: null,
					coverages: [],
					informatic_value: null,
					car_value: null,

					construction_year: null,
					wc_quantity: null,
					rooms_number: null,
					canalization: null,
					canalization_replacement: null,
					size: null,
					habitation_type: null,
					habitation_typology: null,
					habitation_floor: null,					
					security: [],
					ownership: null,
					house_use: null,
					informations:null,
					payment_type: null
				},
			};
		},
		created() {
			this.createYears();
			if(this.getUser){
				this.form.name = this.getUser.name;
				this.form.surname = this.getUser.surname;
				if(this.getUser.birthdate) this.form.birthdate = new Date(this.getUser.birthdate);
				this.form.nif = this.getUser.nif;
				if(this.getUser.postal_code.number) this.form.postal_code = this.getUser.postal_code.number + "-" + this.getUser.postal_code.ext;
				this.form.address = this.getUser.address;
			}
		},
		methods: {
			newAddress(input){
				this.new_address = input;
				if(!input){
					this.form.house_address = null;
					this.form.house_postal_code= null;
				}
			},
			checkFilling(input){
				this.form.house_filling= input;
				if(!input){
					this.form.house_filling_value = null;
				}
			},
			securityInput(option){
				if(this.form.security[0] && this.t.security[this.t.security.length-1] == option) {
					this.form.security = [];
					this.form.security.push(option);
				}
			},
			onNifInput() {
				if (this.form.nif.length == 9)
					this.invalidNif = !validateNIF(this.form.nif);
			},
			onSubmit() {
				this.isLoading = true;
				let copy = Object.assign({}, this.form);
				copy.name = copy.name + " " + copy.surname;
				if(!this.new_address){
					copy.house_address = copy.address;
					copy.house_postal_code = copy.postal_code;
				}
				addSimulation(this.simulationType, copy)
					.then((response) => {
						this.openStep++;
					})
					.catch((error) => {
						if (Array.isArray(error.response.data)) {
							error.response.data.forEach((element) => {
								this.invalids.push(element.param);
							});
          				}
						this.openStep = 0;
						this.$buefy.toast.open({
							message: this.t.api.error,
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goForward() {
				if (this.openStep < this.maxStep) this.openStep++;
			},
			goBack() {
				if (this.openStep > this.minStep) this.openStep--;
			},
			createYears() {
				let currentYear = new Date().getFullYear();
				for (let i = 0; i < 100; i++) {
					this.years.push(currentYear - i);
				}
			},
		},
		computed: {
			canGoNext() {
				let can = false;
				let index = this.openStep + 1;

				switch (index) {
					case 1:
						if (
							this.form.house_value &&
							this.form.house_filling != null &&
							this.form.construction_year &&
							this.form.wc_quantity &&
							this.form.rooms_number &&
							this.form.canalization != null &&
							this.form.size &&
							this.form.habitation_type &&	
							this.form.security.length > 0 &&
							this.form.ownership  &&
							this.form.house_use
						){
							/*if(this.form.house_filling && !this.form.house_filling_value) can = false;
							else if(this.form.coverages.includes(this.t.coverages[3]) && !this.form.car_value) can = false;
							else if(this.form.coverages.includes(this.t.coverages[6]) && !this.form.informatic_value) can = false;*/
							can = true;
						} 
						break;

					case 2:
						if (
							this.form.name &&
							this.form.surname &&
							this.form.birthdate &&
							!this.invalidNif &&
							this.form.postal_code &&
							this.form.address
						)
							can = true;
						break;

					case 3:
						if (
							this.form.payment_type
						)
						can = true;
						 
						break;
					default:
						break;
				}
				can = !can;
				return can;
			},
		},
	};
</script>

<style lang="scss" scoped>
	

	.column > div:first-of-type {
		position: relative;
	}
</style>
<style>
	.b-checkbox.checkbox[data-v-6f91f2e8]:not(.button) {
		margin-right: 0;
	}
</style>