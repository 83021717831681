<template>
	<section>
		<b-sidebar
			type="is-blue"
			:fullheight="true"
			:overlay="isMobile"
			:open="isSidebarOpen"
			:on-cancel="closeSidebar"
			class="sidebar-home"
			:can-cancel="isMobile ? canCancel : null"
		>
			<div class="top-bar ml-3" :class="isChatOpen ? 'sidebar-chat' : ''">
				<b-button v-show="isMobile" @click="closeSidebar" type="is-ghost" class="has-text-white">
					<b-icon class="close-icon" pack="st" icon="close" size="is-large"></b-icon>
				</b-button>
				<img :src="require('@/assets/icons/logo.svg')" alt="S" />
			</div>

			<div :class="isChatOpen ? 'has-background-white chat-overflow' : ''">
				<b-menu v-if="!isChatOpen">
					<b-menu-list>
						<b-menu-item
							:active="$route.name == 'MyInsurances'|| $route.name == 'UserHome'"
							@click="goTo('MyInsurances')"
							class="has-text-white is-title-2-m"
							:label="t.insurance"
						></b-menu-item>

						<!--<b-menu-item
							:active="$route.name == 'UserHome'"
							@click="goTo('UserHome')"
							class="has-text-white is-title-2-m is-active"
							:label="t.general"
						></b-menu-item>-->

						<b-menu-item
							:active="$route.name == 'MySimulations'"
							@click="goTo('MySimulations')"
							class="has-text-white is-title-2-m"
							:label="t.simulation"
						></b-menu-item>

						<b-menu-item
							:active="$route.name == 'Chat'"
							@click.stop="onChatClick"
							class="has-text-white is-title-2-m has-position-relative sidebar-show-inside"
							:label="t.chat"
						>
							<span v-if="getTotalUnread > 0" class="badge is-green">{{getTotalUnread}}</span>
						</b-menu-item>

						<b-menu-item
							:active="$route.name == 'Settings'"
							@click="goTo('Settings')"
							class="has-text-white is-title-2-m"
							:label="t.settings"
						></b-menu-item>

						<b-menu-item class="has-text-white is-title-2-m" :label="t.logout" @click="execLogout"></b-menu-item>
					</b-menu-list>
				</b-menu>
				<b-menu v-else>
					<b-button
						type="is-ghost"
						class="is-body-m has-icon-margin"
						icon-pack="st"
						icon-left="arrow-left"
						@click.stop="setChatOpen(false); goTo('MyInsurances')"
					>{{$t("shared.goBack")}}</b-button>

					<b-menu-list>
						<ChatUser
							v-for="room in getRooms"
							:key="room._id"
							@click="onChatOpen(room._id)"
							:room="room"
						/>
					</b-menu-list>
				</b-menu>
			</div>
		</b-sidebar>
	</section>
</template>

<script>
	import ChatUser from "../Chat/User.vue";

	import chat from "@/mixins/modules/chat";

	export default {
		name: "Sidebar",
		mixins: [chat],
		components: { ChatUser },
		data() {
			return {
				t: this.$t("components.Home.Sidebar"),
				canCancel: ["escape", "x", "outside"],
			};
		},
		created() {
			if(this.$route.name == 'MyInsurances'|| this.$route.name == 'UserHome') this.closeSidebar();
		},
		methods: {
			closeSidebar() {
				this.setSidebarOpen(false);
			},
			execLogout() {
				this.logout();
			},
			onChatClick() {
				this.setChatOpen(true);
				this.onChatOpen(this.getRooms[0]._id);
			},
			onChatOpen(id) {
				this.setSelectedRoom(id);
				this.$router.push({
					name: "Chat",
					params: { id },
				});
				this.closeSidebar();
			},
			goTo(name) {
				this.$router.push({ name });
				this.closeSidebar();
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	.top-bar {
		display: flex;
		img {
			margin-left: $space/2;
			max-width: 36px;
		}
	}

	.chat-overflow {
		overflow: auto;
		flex: 1;
	}

	.badge {
		right: 2rem;
		top: 50%;
	}
	.sidebar-chat {
		height: 100px !important;

	}
</style>