<template>
	<div class="mt-8 main" v-if="!isLoading">
		<!--<b-loading :active="isLoading"></b-loading>-->
		<div class="columns is-mobile">
			<div class="column is-offset-1 is-10 level is-mobile">
				<h4 class="is-headline-m-m is-big-title-m-d level-left">{{t.simulations}}</h4>
				<a v-if="!see_all && !isMobile" class="is-caption-m level-right-desktop" @click="see_all=true">{{$t("shared.see_all")}}</a>
				<a v-else-if="!isMobile" class="is-caption-m level-right-desktop" @click="see_all=false">{{$t("shared.see_less")}}</a>
			</div>
		</div>
		<div v-if="see_all || isMobile" class="columns is-mobile is-multiline" :class="{'ml-8':!isMobile, 'is-centered':isMobile}">
			<div v-for="(sim, i) in simulations"
					:key="i" class="column is-4-fullhd is-6-desktop is-8-mobile">
				<Card
					@click.native="goToSimulation(sim)"
					:slot1="slot1[i]"
					:slot2="slot2[i]"
					:slot3="slot3[i]"
					:showBadge="badge[i]"
					:badgeCount="badgeCount[i]"
				/>
			</div>
		</div>
		<div v-else class="columns is-mobile is-multiline">
			<div class="column is-offset-1 is-10 card-row">
				<Card
					@click.native="goToSimulation(sim)"
					v-for="(sim, i) in simulations"
					:key="i"
					:slot1="slot1[i]"
					:slot2="slot2[i]"
					:slot3="slot3[i]"
					:showBadge="badge[i]"
					:badgeCount="badgeCount[i]"
				/>
			</div>
		</div>
		<!--<div v-if="!see_all" class="columns is-mobile is-multiline pt-4">
			<div class="column is-offset-1 is-10-touch is-10-desktop is-4-fullhd st-card p-8">
				<h4 class="is-headline-m-m is-title-2-m-d level-left">{{$t('shared.activity')}}</h4>
				<Steps :steps="steps" />
			</div>
		</div>-->
		<div class="fix_bottom">
			<p class="has-text-black is-body-m-m has-text-centered">
				<small>As simulações apresentadas são meramente informativas!</small>
			</p>
		</div>
	</div>
</template>

<script>
	import { getSimulations } from "@/api/simulations";
	//import { getActivities } from "@/api/users";

	import Card from "@/components/Simulations/Card";
	//import Steps from "@/components/Shared/Steps";

	export default {
		name: "MySimulations",
		components: { Card },
		data() {
			return {
				isLoading: true,
				simulations: [],
				t: this.$t("components.Home.MySimulations"),
				slot1: [],
				slot2: [],
				slot3: [],
				slot4: [],
				badge:[],
				badgeCount:[],
				steps: [],
				see_all: false
			};
		},
		created() {
			this.getSimulations();
			//this.getActivities();
		},
		methods: {
			getSimulations() {
				this.isLoading = true;
				getSimulations({order:"desc"})
					.then((response) => {
						this.simulations = response.data.data;
						this.simulations.forEach((simulation,index) => {
							this.slot1.push(this.$t('insuranceTypes.' + simulation.simulation_type));
							if(simulation.new_proposals != 0) this.badge.push(true);
							else this.badge.push(false);
							this.badgeCount.push(simulation.new_proposals);
							if (simulation.simulation_type == 'car' || simulation.simulation_type == 'company_car'){
								this.slot2.push(`${simulation.data.car.brand} ${simulation.data.car.model}`);
								this.slot3.push(simulation.data.car.plate)
							} else if (simulation.simulation_type == 'life') {
								this.slot2.push(simulation.data.name + " " + simulation.data.surname);
								this.slot3.push(simulation.data.capital + this.$t('shared.currency'));
							} else if (simulation.simulation_type =="personal_accident") {
								this.slot2.push(simulation.data.name + " " + simulation.data.surname);
								this.slot3.push(simulation.data.contract_value + this.$t('shared.currency'));
							}  else if (simulation.simulation_type == 'health' || 
									   simulation.simulation_type == 'teeth' || 
									   simulation.simulation_type == 'personal_accident') {
								this.slot2.push(simulation.data.name + " " + simulation.data.surname);
								this.slot3.push("");
							} else if (simulation.simulation_type == 'house') {
								this.slot2.push(simulation.data.name);
								this.slot3.push(simulation.data.house_value + this.$t('shared.currency'));
							} else if (simulation.simulation_type == 'work') {
								this.slot2.push( simulation.data.employee.name);
								this.slot3.push( simulation.data.employee.profession);
							} else if (simulation.simulation_type == 'multi_risk_house') {
								this.slot2.push( simulation.data.address);
								this.slot3.push("");
							} else if (simulation.simulation_type == 'motorcycle'){
								this.slot2.push(`${simulation.data.motorcycle.brand} ${simulation.data.motorcycle.model}`);
								this.slot3.push(simulation.data.motorcycle.plate)
							} else {
								if(simulation.data.surname) this.slot2.push(simulation.data.name + " " + simulation.data.surname);
								else this.slot2.push(simulation.data.name);
								this.slot3.push("");
							}
						});
					})
					.catch((error) => {
						this.$buefy.toast.open({
							message: this.t.no_data,
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			/*getActivities() {
				this.isLoading = true;
				getActivities()
					.then((response) => {
						let activities = response.data.data;
						activities.forEach(activity =>{
							this.steps.push({
								left: this.$t('steps.'+ activity.activity),
								right: this.moment(activity.createdAt).fromNow(),
							})
						});
						console.log();
					})
					.catch((error) => {})
					.finally(() => {
						this.isLoading = false;
					});
			},*/
			goToSimulation(sim) {
				this.$router.push({name: 'SimulationID', params: {id: sim._id}})
			}
		},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	.fix_bottom {
		@include from($mobile){
			bottom: 1rem;
			width: 100%;
		}
	}
	.main {
		@include until($mobile){
			padding-bottom: 20vh;
		}
		@include from($mobile){
			padding-bottom: 10vh;
		}
	}
</style>