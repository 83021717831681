<template>
	<div class="level is-mobile p-8">
		<div class="level-left">
			<img :src="require('@/assets/icons/logo.svg')" alt="S" />
		</div>
		<div class="level-right">
			<b-button @click="$router.go(-1)" type="is-ghost" class="has-text-white">
				<b-icon class="close-icon" pack="st" icon="close" size="is-large"></b-icon>
			</b-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CloseTopbar",
	};
</script>

<style lang="scss" scoped>
</style>