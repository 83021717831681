<template>
	<div class="has-background-gray-light is-full-viewport-height">
		<b-loading :active="isLoading"></b-loading>
		<div class="has-background-blue columns is-mobile is-multiline">
			<div class="column is-12 pb-0">
				<b-button
					type="is-ghost"
					class="has-text-white is-body-m has-icon-margin"
					icon-pack="st"
					icon-left="arrow-left"
					@click="$router.go(-1)"
				>{{$t("shared.goBack")}}</b-button>
			</div>
			<div class="column has-text-white mr-4 ml-4 level is-mobile">
				<h3 class="level-left is-title-1-m">{{t.insurance}}</h3>
				<div class="level-right">
					<h3
					class="level-item is-body-m"
				>{{insurance ? $t(`insuranceTypes.${insurance.insurance_type}`) : ''}}</h3>
					<a class="level-item is-body-m" :href="'/home/chat/'+insurance.room"><span class="mdi mdi-chat-processing chat-icon"></span></a>
				</div>
				
			</div>
		</div>
		<div v-if="!insurance"></div>
		<div v-else>
			<!--<div class="columns is-mobile">
				<div class="column is-10-desktop is-offset-1-desktop is-11-touch is-offset-0_5-touch">
					<AutoData class="mt-8" :simulation="insurance.simulation" :isCollapse="true" />
				</div>
			</div>-->

			<div class="columns is-mobile is-multiline mt-16">
				<div
					class="column has-background-white is-10-touch is-offset-1-touch is-4-desktop is-offset-1-desktop pl-6 pr-6 st-card"
					:class="isCollapseOpen ? 'py-6' : 'py-1'"
				>
					<b-collapse :open.sync="isCollapseOpen" aria-id="proposalData">
						<template #trigger>
							<div class="columns is-mobile">
								<div class="is-align-self-center column is-8">
									<h1 class="is-headline-m">{{insurance.mediator.business_name}}</h1>
								</div>
								<div class="column is-4">
									<b-button
										size="is-large"
										icon-pack="st"
										:icon-right="!isCollapseOpen ? 'arrow-down-circle' : 'arrow-up-circle'"
										class="no-focus float-right"
										type="is-ghost"
										aria-controls="proposalData"
									/>
								</div>
							</div>
						</template>
						<div class="columns is-mobile is-multiline">
							<div class="column is-12">
								<p class="is-subhead-m has-text-gray">{{t.mediatorData.name}}</p>
								<p class="is-headline-m has-text-black">{{insurance.mediator.name}} {{insurance.mediator.surname}}</p>
							</div>
							<div class="column is-12">
								<p class="is-subhead-m has-text-gray">{{t.mediatorData.email}}</p>
								<p class="is-headline-m has-text-black">{{insurance.mediator.email}}</p>
							</div>
							<div class="column is-12">
								<p class="is-subhead-m has-text-gray">{{t.mediatorData.postal_code}}</p>
								<p class="is-headline-m has-text-black">{{insurance.mediator.postal_code.number}}-{{insurance.mediator.postal_code.ext}}</p>
							</div>
							<div v-if="insurance.mediator.nif" class="column is-12">
								<p class="is-subhead-m has-text-gray">{{t.mediatorData.nipc}}</p>
								<p class="is-headline-m has-text-black">{{insurance.mediator.nif}}</p>
							</div>
						</div>
					</b-collapse>
				</div>
				<div class="column is-10-touch is-offset-1-touch is-5-desktop is-offset-1-desktop mt-6">
					<div class="columns is-mobile is-multiline">
						<div class="column is-12 level is-mobile mb-0">
							<h3 class="is-headline-m level-left">{{t.details}}</h3>
						</div>
						<div class="column is-12">
							<b-table
								class="st-table has-text-black has-background-gray-light"
								:hoverable="true"
								:mobile-cards="false"
								:data="getData"
								:columns="getColumns"
								:sticky-header="true"
							></b-table>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>
</template>

<script>
	import { getProposalById } from "@/api/proposals";
	import AutoData from "@/components/Data/Auto";
	export default {
		name: "InsuranceID",
		components: {
			AutoData
		},
		data() {
			return {
				isCollapseOpen: false,
				t: this.$t("components.Insurances.InsuranceID"),
				insurance: null,
				isLoading: false,
				iconIncludes:
					'<span class="icon is-medium has-text-blue"><i class="st st-tick"></i></span>',
				iconDoesntInclude:
					'<span class="icon is-medium has-text-red"><i class="st st-close"></i></span>',
			};
		},
		created() {
			this.getProposalById();
		},
		methods: {
			getProposalById() {
				this.isLoading = true;
				getProposalById(this.$route.params.id)
					.then((response) => {
						this.insurance = response.data.data;
						this.insurance.simulation.user = this.insurance.user;
					})
					.catch((error) => {})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
		computed: {
			getData() {
				let data = [];

				let values = {
					description: this.insurance.plan
				};
				
				values[this.insurance.type_payment] = this.insurance.value[this.insurance.type_payment];

				data.push(values);

				return data;
			},
			getColumns() {
				let columns = [
					{
						field: "description",
						sticky: true,
						headerClass: "is-subhead-m",
						width: "135px",
					},
					{
						field: this.insurance.type_payment,
						label: this.$t("payment_split")[this.insurance.type_payment],
						headerClass: "is-subhead-m",
						centered: true
					}
				];

				return columns;
			},
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

	.st-card {
		@include from($touch) {
			height: fit-content;
		}
	}
	
	.chat-icon {
		color: #fff;
	}
</style>